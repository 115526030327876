<template>
  <div class="kDatepicker-wrapper">
    <input type="text" class="input-style"
    ref="input"
    :value="passValue"
    @input="passData()"
    @keydown="keyValidation"
    @focus="state.showCalendar= true"
    @blur="clear"
    placeholder="DD-MM-RRRR"
    pattern="\d-*"
    inputmode="numeric"
    :class="{'isOpen': state.showCalendar}"
    >

    <div class="icon" @click="state.showCalendar= !state.showCalendar"></div>

    <kCalendar v-if="state.showCalendar" 
    :selected="calendar.select" 
    :save="calendar.save"
    :rangeStart="rangeStart"
    :rangeEnd="rangeEnd"
    :key="String(generateUniqueKey())"
    :name="String(generateUniqueKey())"
    @setDateFromCalendar="setDateFromCalendar"
    @close="close"/>
  </div>
</template>

<script>
import kCalendar from './kCalendar.vue'
export default {
  name: 'kDatepicker',
  components: {
    kCalendar
  },
  props: {
    range: {
      type: Object,
      required: true,
      default: () => {
        return {
          from: 'current', 
          to: 'current'
        }
      }
    },
    openDate: {
      type: Date,
      default: new Date()
    },
    value: {
      type: String
    },
    userYears: {
      type: Object,
      default: () => {
        return {from: 18, to: 85}
      }
    }
  },
  created() {
    this.calendar.select.year = (this.calendar.select.year == null) ? this.openDate.getFullYear() : this.calendar.select.year;
    this.calendar.select.month = (this.calendar.select.month == null) ? this.openDate.getMonth() + 1: this.calendar.select.month;
    this.calendar.select.day = (this.calendar.select.day == null) ? this.openDate.getDate() : this.calendar.select.day;
  },
  data() {
    return {
      kValue: '',
      state: {
        showCalendar: false,
        filled: false
      },
      options: {
        separator_position: [2,5]
      },
      calendar: {
        select: {
          year: null,
          month: null,
          day: null
        },
        save: {
          year: null,
          month: null
        }
      }

    }
  },
  methods: {
    onlyNumbers(e, specialKey) {
      var isNumber = ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || specialKey);
      if (!isNumber) {e.preventDefault()}
    },
    keyValidation(e) {
      var specialKeys = [13,8,9,46,37,39];
      var isSpecial = (specialKeys.includes(e.keyCode));
      
      if (!this.state.filled) {
        this.onlyNumbers(e, isSpecial);
      } else if (isSpecial) {
        return
      } else {e.preventDefault()}
    },
    setSeparator(value) {
      if (this.options.separator_position.includes(value.length)) return value + "-";
      return value;
    },
    setDateFromCalendar(payload) {
      var dd = (payload.day.toString().length === 1) ? '0'+payload.day : payload.day;
      var mm = (payload.month.toString().length === 1) ? '0'+payload.month : payload.month;
      this.kValue = dd + "-" + mm + '-' + payload.year;
    },
    validate(dd,mm,yyyy) {
      var validDays;
      var yearCheck = (yyyy > this.rangeEnd.year || yyyy < this.rangeStart.year);
      var monthBordersError = false;
      var dayBordersError = false;

      if ((this.rangeEnd.year === Number(yyyy) && this.rangeEnd.month < Number(mm)) ||
          (this.rangeStart.year === Number(yyyy) && this.rangeStart.month > Number(mm))
          ) {
        monthBordersError = true
      }

      if ((this.rangeEnd.year === Number(yyyy) && this.rangeEnd.month === Number(mm) && this.rangeEnd.day < Number(dd)) ||
          (this.rangeStart.year === Number(yyyy) && this.rangeStart.month === Number(mm) && this.rangeStart.day > Number(dd))
          ) {
        dayBordersError = true
      }

      // Check years
      if (yearCheck) {
        this.kValue='';
        return false;
      }

      // Check months
      if (monthBordersError || (mm > 12 || mm <= 0)) {
        this.kValue='';
        return false;
      }

      // Check days
      if (mm === 2) {
          if (yyyy % 4 != 0) {validDays = 28} else {validDays = 29}
      } else {
          if (mm < 8) {
              if (mm % 2 != 0) {validDays = 31} else {validDays = 30}
          } else {
              if (mm % 2 == 0) {validDays = 31} else {validDays = 30}
          }
      }

      if (dayBordersError || (dd > validDays || dd <= 0)) {
        this.kValue='';
        return false;
      }

      return true
    },
    setSelect(dd,mm,yyyy) {
      this.calendar.select.year = parseInt(yyyy);
      this.calendar.select.month = parseInt(mm);
      this.calendar.select.day = parseInt(dd);
    },
    saveSelected(dd,mm,yyyy) {
      this.$set(this.calendar.save,'month', parseInt(mm));
      this.$set(this.calendar.save,'year', parseInt(yyyy));
    },
    close() {
      this.state.showCalendar = false;
    },
    clear() {
      if (!this.state.filled) {
        this.kValue = '';
      }
    },
    passData() {
      this.inputValue = this.$refs.input.value;
    },
    generateUniqueKey() {
      return new Date().getUTCMilliseconds();
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.kValue
        },
      set(value) {
        this.kValue = this.setSeparator(value);
      }
    },
    rangeStart() {
      return {
        year: (this.range.from == 'current') ? new Date().getFullYear() - this.userYears.to : this.range.from.getFullYear() - this.userYears.to,
        month: (this.range.from == 'current') ? new Date().getMonth()+1 : this.range.from.getMonth(),
        day: (this.range.from == 'current') ? new Date().getDate() : this.range.from.getDate()
      }
    },
    rangeEnd() {
      return {
        year: (this.range.to == 'current') ? new Date().getFullYear() - this.userYears.from : this.range.to.getFullYear() - this.userYears.from,
        month: (this.range.to == 'current') ? new Date().getMonth()+1 : this.range.to.getMonth(),
        day: (this.range.to == 'current') ? new Date().getDate() : this.range.to.getDate()
      }
    },
    passValue() {
      this.inputValue = this.value;
      return this.value
    }
  },
  watch: {
    inputValue(value) {
      var pattern = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;

      this.state.filled = (value.length >= 10);

      if (this.state.filled && pattern.test(this.kValue)) {
        var [dd,mm,yyyy] = (this.kValue.split('-'));
        if (this.validate(dd,mm,yyyy)) {
          this.setSelect(dd,mm,yyyy);
          this.saveSelected(dd,mm,yyyy);
        }
      }
      if (this.state.filled && !pattern.test(this.kValue)) this.kValue='';
    },
    kValue(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.kDatepicker-wrapper {
  // width: 303px;
  flex-basis: 303px;
  position: relative;
  display: flex;

  .input-style {
    // width: 250px;
    order: 0;
    height: 44px;
    box-sizing: border-box;
    font-weight: 600;
    text-align: left;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
    // width: 300px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    &::placeholder {
      color: #b5b5b5;
      font-weight: 400;
    }
    &:focus {
      outline-width: 0;
    }
    &.isOpen {
      box-shadow: 0 0 0 4px rgba(32,164,222,.25);
      border-color: rgba(32,163,221,.6);
      z-index: 1;
    }
  }
  .icon {
    order: 2;
    display: flex;
    background: #f3f3f3;
    border-bottom-color: #d5d5d5!important;
    border: 1px solid #d5d5d5;
    color: #828282;
    text-shadow: 0 1px 0 hsla(0,0%,100%,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    top: 0;
    position: relative;
    width: 55px;
    text-align: center;
    font-size: 14px;
    left: -3px;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    &::after {
      content: '';
      display: block;
      background: url('../../assets/img/icon-calendar.svg') no-repeat ;
      width: 18px;
      height: 20px;
      background-size: contain;
      opacity: .3;
    }
  }
}
</style>
