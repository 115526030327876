<template>
    <div class="new-slide-wrapper">
        <div class="header-wrapper">
            <div class="header">{{header}}</div>
            <template v-if="!range">
                <div>
                    <div class="range-block">
                        <kInput v-model="sliderData" :lazy="lazy || lazyInput" :icon="unit" 
                                :iconPosition="'right'" 
                                :allow="'numbers'" 
                                :smallInput="true"
                                :min="settings.min"
                                :max="settings.max"
                                :setWidth="inputWidth"
                                :extraValidation="extraValidation"
                                ></kInput>
                    </div>
                </div>
            </template>
            <template v-else >
                <div>
                    <div class="range-block">
                        <span class="text">od</span>
                        <kInput v-model="sliderData[0]" :lazy="lazy || lazyInput" :icon="unit" 
                                :iconPosition="'right'" 
                                :allow="'numbers'" 
                                :smallInput="true"
                                :min="settings.min"
                                :max="settings.max"
                                :setWidth="inputWidth"
                                :extraValidation="extraValidation"
                                ></kInput>
                    </div>
                    <div class="range-block">
                        <span class="text">do</span>
                        <kInput v-model="sliderData[1]" :lazy="lazy || lazyInput" :icon="unit" 
                                :iconPosition="'right'" 
                                :allow="'numbers'" 
                                :smallInput="true"
                                :min="settings.min"
                                :max="settings.max"
                                :setWidth="inputWidth"
                                :extraValidation="extraValidation"
                                ></kInput>
                    </div>
                </div>
            </template>
        </div>
        <vue-slider v-model="sliderData" 
                    :data="settings.data"
                    :min="settings.min"
                    :max="settings.max" 
                    :tooltip="'none'"
                    :width="'100%'"
                    :height="'auto'"
                    :lazy="lazy"
                    :interval="interval"
        />
        <div class="labels-wrapper">
            <div class="kSlider-label" v-for="(label,index) in labels" :key="index+'_label'">{{label.label}}</div>
        </div>

    </div>
</template>

<script>
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js';
import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/default.css';
import kInput from './kInput';

export default {
    name: 'kSlider',
    data() {
        return {
            settings: {
                data: this.params,
                min: this.min,
                max: this.max
            },
        }
    },
    components: {
        VueSlider, kInput
    },
    computed: {
        sliderData: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
    },
    props: {
        header: {
            type: String,
            default: ''
        },
        params: {
            type: Array,
            default: null
        },
        labels: {
            type: Array,
            default() {return [{label: '0'},{label: '100%'}]}
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        value: {
            type: [String, Number, Array]
        },
        lazy: {
            type: Boolean,
            default: false
        },
        range: {
            type: Boolean,
            default: false
        },
        unit: {
            type: String,
            default: ''
        },
        interval: {
            type: Number,
            default: 1
        },
        inputWidth: {
            default: '120px',
            type: String
        },
        lazyInput: {
            default: false,
            type: Boolean
        },
        extraValidation: {
            default: (e) => {},
            type: Function
        },
    },
    watch: {
        sliderData(value) {
            if (value > this.max) this.sliderData = this.max
            if (value < this.min) this.sliderData = this.min
        }
    }
    
}
</script>

<style lang="scss">
.new-slide-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .vue-slider {
        .vue-slider-rail {
            height: 8px;
            background-color: #e0e0e0;
            .vue-slider-dot {
                background-color: white;
                width: 26px !important;
                height: 26px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                border-radius: 20px;
                border: 1px solid rgba(0,0,0, 0.05);
                position: relative;
                top: -8px;
                cursor: pointer;
                &::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    background-color: #20a3dd;
                    border-radius: 20px;
                }
                &:nth-child(2n-1) {
                    top: -24px !important;
                }
                .vue-slider-dot-handle {
                    position: absolute;
                    z-index: -1;
                }
            }
        }
    }
}
</style>


<style lang="scss" scoped>

$xlarge: 1800px;
$large: 1200px;
$medium: 1024px;
$small: 640px;
$xsmall: 420px;

$lightblue: #ddf3f8;
$blue: #20a3dd;
$darkblue: #012174;

$lightgreen: #EAF7E5;
$green: #6FCA49;

.new-slide-wrapper {
    text-align: left;
    margin-bottom: 10px;
    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        
        .header {
            color: $darkblue;
            font-weight: 600;
        }
        .range-block {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &:not(:first-of-type) {
                margin-top: 5px;
            }
            .text {
                font-size: 10px;
                margin-right: 5px;
            }
            .unit {
                // margin-left: 5px;
                border-top: 1px solid #ddd;
                border-right: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                color: #959595;
                background-color: #f3f3f3;
                flex-basis: 40px;
                justify-content: center;
                align-items: center;
                display: flex;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                height: 35px;
            }
        }
        .value {
            // color: $blue;
            // font-weight: 700;
            // font-size: 12px;
            // appearance: textfield;
            // text-align: right;
            // padding: 0px 5px;
            // border: 1px solid rgba(0, 0, 0, 0.1);
            // border-radius: 5px;
            // box-sizing: border-box;
            // height: 25px;

            height: 35px;
            text-align: left;
            padding: 5px 5px 5px 10px;
            background-color: #fff;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border: 1px solid #bee1ab;
            font-weight: 600;
            width: 100px;
            min-width: 0px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }
            &:focus {
                outline: none;
                box-shadow: 0 0 0 4px rgba(32,164,222,.25);
                border-color: rgba(32,163,221,.6);
                z-index: 1;
            }
        }
    }
    .labels-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .kSlider-label {
            margin-top: 8px;
            font-size: 10px;
        }
    }
    
}
</style>

