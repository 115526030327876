<template>
    <div class="input-wrapper" :class="[{'right': iconPosition === 'right' && icon},{'left': iconPosition === 'left' && icon}, {'kInput--small': smallInput}]"
        :style="[setWidth && {width: setWidth}]">
        <div v-if="icon" class="icon" v-html="icon"></div>
        <input :type="setType" class="kInput" 
            @keydown="keySpy($event)" 
            @input="input($event)" 
            @blur="blur($event)" 
            ref="input" 
            :value="valueParse" 
            :placeholder="placeholderParse" 
            :disabled="!enableIf"
            :min="min"	
            :max="max"
            >
    </div>
</template>

<script>
export default {
    props: {
        name: {
            default: 'kInput'
        },
        min: {	
            default: null,
            type: Number	
        },	
        max: {	
            default: null,
            type: Number	
        },
        value: {
            type: [String, Number]
        },
        icon: {
            type: String,
            default: ''
        },
        iconPosition: {
            type: String,
            default: 'right'
        },
        allow: {
            type: String,
            default: 'all'
        },
        allowExpand: {
            type: Array,
            default: null
        },
        keyDebug: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null
        },
        mask: {
            default: null,
            type: String
        },
        lazy: {
            default: false,
            type: Boolean
        },
        enableIf: {
            default: true
        },
        extraValidation: {
            default: (e) => {},
            type: Function
        },	
        smallInput: {	
            type: Boolean,	
            default: false
        },
        urlSync: {
            default: function() {
                return {
                    active: false,
                    paramName: '',
                    refresh: false,
                    afterUpdate: null
                }
            },
            type: Object
        },
        selectNext: {
            default: true,
            type: Boolean
        },
        setWidth: {
            default: '',
            type: String
        }
    },
    computed: {
        valueParse() {
            if (this.mask) return this.maskGenerator(this.value);
            return this.value
        },
        placeholderParse() {
            if (this.mask && !this.placeholder) return this.mask.replace(/\#/g,'_') 
            return this.placeholder
        },
        parseUrlParamName() {
            return (this.urlSync.paramName) ? this.urlSync.paramName : 'Unfilled-param-name'
        },
        setType() {
            return (this.allow === 'numbers') ? 'number' : 'text'
        }
    },
    methods: {
        input(e) {
            if (!this.lazy) this.setValue(e);
        },
        blur(e) {
            if (this.lazy) {
                this.setValue(e);
                if (this.urlSync.active) this.urlValueSync();
            }
        },
        setValue(e) {
            if (this.allow === 'numbers') this.removeFirstZeroFN(e);
            this.extraValidation(e);
            this.$emit('input', this.$refs.input.value);
        },
        keySpy(e) {
            var positive = true;
            var deleteKeys = e.which == 8 || e.which == 46;
            var tab = e.which == 9;
            var enter = e.which == 13;
            var extraKey = deleteKeys || e.which == 32 || tab;
            var expand = false;
            var keyDebug = this.keyDebug;

            if (keyDebug) console.log('keyDebug: ',e.which);
            if (enter) {
                this.setValue(e);
                if (this.selectNext) this.setNextInput();
                if (!this.selectNext && this.urlSync.active) this.urlValueSync();
                }
            if (this.allowExpand) expand = this.allowExpand.some(key => key == e.which)

            if (this.allow === 'letters') positive = ((e.which >= 65 && e.which <= 90) || extraKey || expand)
            else if (this.allow === 'numbers') positive = ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105) || extraKey || expand)

            if (this.mask && deleteKeys) this.deleteSeparator(e);
            if (!positive || (this.mask && this.mask.length === this.value.length && !deleteKeys && !tab)) {e.preventDefault()}
        },
        maskGenerator(value) {
            let nextMarkInMask = this.mask[value.length];
            if (nextMarkInMask !== '#' && nextMarkInMask) {
                this.$emit('input', `${value}${nextMarkInMask}`);
                return `${value}${nextMarkInMask}`
            }
            return value
        },
        deleteSeparator(e) {
            let prevMarkInMask = this.mask[this.valueParse.length-1];
            let indexBeforeSeparator = this.valueParse.length-2;
            let emitValue = this.$refs.input.value.slice(0,indexBeforeSeparator);
            if (prevMarkInMask !== '#' && prevMarkInMask) {
                e.preventDefault();
                this.$emit('input',emitValue);
            }
        },
        removeFirstZeroFN(e) {
            let value = e.target.value;
            let search = /(^0+)\d/g;
            let removeRegEx = /^0+/g
            if (search.test(String(value))) {
                value = value.replace(removeRegEx,'')
                e.srcElement.value = value;
            }
        },	
        setNextInput() {	
            let allInputs = document.querySelectorAll('input.kInput');	
            allInputs = [...allInputs];	
            let thisInput = this.$refs.input;	
            let selectedInputIndex = allInputs.findIndex((input) => input == thisInput);	
            if (allInputs.length-1 > selectedInputIndex) {	
                allInputs[selectedInputIndex+1].focus();	
            }	
        },
        async urlValueSync() {
            let urlData = new URL(window.location.href);
            let itsNewValue = (this.valueParse != this.$refs.input.value)
            urlData.searchParams.set(this.parseUrlParamName,this.$refs.input.value);

            if (this.urlSync.afterUpdate && itsNewValue) {
                let fn = await this.urlSync.afterUpdate();
            }
            if (!this.urlSync.refresh) window.history.replaceState(null, '', urlData)
            else {
                if (itsNewValue) window.location.href = urlData;
            }
        }
    },
    watch: {
        enableIf(value) {
            if (!value) {
                this.$emit('input','')
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    display: flex;
    flex-basis: 300px;
    .kInput {
        order: 0;
        display: flex;
        flex: 0 1 100%;
        height: 44px;
        box-sizing: border-box;
        font-weight: 600;
        text-align: left;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #ddd;
        font-size: 13px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        margin-bottom:0px;
        min-width: 0;
        &::placeholder {
            font-weight: 400;
            color: rgb(218, 218, 218);
        }
        &:focus {
            outline-width: 0;
            box-shadow: 0 0 0 4px rgba(32,164,222,.25);
            border-color: rgba(32,163,221,.6);
            z-index: 1;
        }
        &:disabled {
            background-color: #f5f5f5;
        }
    }

     .icon {
        display: flex;
        flex: 1 0 56px;
        background: #f3f3f3;
        border-bottom-color: #d5d5d5!important;
        border: 1px solid #d5d5d5;
        color: #959595;
        text-shadow: 0 1px 0 hsla(0,0%,100%,.7);
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 13px;
        line-height: 1;
        position: relative;
        box-sizing: border-box;
    }

    &.left {
        .kInput {
            border-radius: 0px 4px 4px 0px;
        }
        .icon {
            order: 0;
            left: 1px;
            border-radius: 5px 0px 0px 5px;
        }
    }

    &.right {
        .kInput {
            border-radius: 4px 0px 0px 4px;
        }
        .icon {
            order: 1;
            left: -1px;
            border-radius: 0 5px 5px 0;
        }
    }

    &.kInput--small {	
        flex-basis: 110px;	
        .kInput {	
            height: 35px;	
        }	
        .icon {	
            flex: 1 0 40px;	
            height: 35px;	
        }	
    }
}
</style>


