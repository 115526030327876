<template>
    <div>
        <modalWrapper v-if="showModal && status === 'showQuestion'" @close="close" :maxWidth="'520px'" 
            :secBtn="true" :secBtnText="'Uzupełnij'" :secBtnFN="fillData">
            <template #title>Uzupełnienie danych</template>
            <template #content>
                <div class="modalTipBody">
                    W {{days[savedDate.getDay()]}} ({{savedDate.getDate()}}.{{savedDate.getMonth()+1}}.{{savedDate.getFullYear()}}) została wykonana kalkulacja. 
                    Czy przywrócić jej dane?
                </div>
            </template>
        </modalWrapper>
    </div>
</template>

<script>
import modalWrapper from './modalWrapper';
import { KDataWatcher } from '../assets/js/KDataWatcher.js';
import { mapMutations, mapState, mapGetters } from 'vuex';

export default {
    components: {
        modalWrapper
    },
    props: {
        ask: {
            type: Boolean,
            default: true
        },
        askAfter: {
            type: Number,
            default: 0 //days
        },
        removeAfter: {
            type: Number,
            default: 7 //days
        },
        kDataWatcherSettings: {
            type: Object,
            required: true
        },
        dataState: {
            type: Object,
            required: true
        },
        calculation: {
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            default: false
        },
        agreementsKey: {
            default: 'agreements',
            type: String
        },
        instaFill: {
            default: false,
            type: Boolean
        },
        fillIf: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            showModal: true,
            days: ['niedzielę', 'poniedziałek', 'wtorek', 'środę', 'czwartek', 'piątek', 'sobotę'],
            status: null
        }
    },
    computed: {
        dataComparisonEngine() {
            var debug = this.debug;
            if (Object.keys(this.kDataWatcherSettings.savedState).length > 0 && this.kDataWatcherSettings.isFilled) {
                var result = false;
                for (let key in this.dataState) {
                    let item = (this.dataState[key] !== null && this.dataState[key].hasOwnProperty('indexedDB')) ? this.dataState[key] : false;
                    if (item && key !== this.agreementsKey) {
                        let toCompare = this.kDataWatcherSettings.savedState.data[key];
                        if (item.value === toCompare) {
                        if (debug) console.log(`(key: ${key}) item.value === toCompare: ${item.value} === ${toCompare}, Result: ${item.value === toCompare}`);
                        result = true
                        } else {
                            if (debug) console.log(`(key: ${key}) item.value !== toCompare: ${item.value} !== ${toCompare}, Result: ${item.value === toCompare}`);
                            result = false;
                            break;
                        }
                    }
                }
                return !result
            }
        },
        savedDate() {
            return this.kDataWatcherSettings.savedState.date || false
        },
        dayDifferent() {
            if (this.savedDate) {
                return this.DaysBetween(this.savedDate,new Date());
            }
        },
    },
    methods: {
        fillData() {
            if (this.fillIf) {
                this.fillControler();
                this.showModal = false;
            }
        },
        close() {
            this.removeDB();
            this.showModal = false;
        },
        removeDB() {
            KDataWatcher.removeDB({dbname: this.kDataWatcherSettings.dbname});
        },
        DaysBetween(dateSaved,currentDate) {
            var one_day=1000*60*60*24;

            var date1_ms = dateSaved.getTime();
            var date2_ms = currentDate.getTime();

            var difference_ms = date2_ms - date1_ms;
                
            return Math.floor(difference_ms/one_day); 
        },
        setStatus({days}) {
            if ((days < this.askAfter) || this.instaFill) {
                if (this.fillIf) {
                    this.fillControler();
                    this.status = "fill"
                }
            }
            else if (this.ask && days >= this.askAfter && days <= this.removeAfter) {this.status = "showQuestion"}

            if (days > this.removeAfter) {
                this.removeDB();
                this.status = "remove"
            }
        },
        fillControler() {
            function fillData({array, root}) {
                for (let key in array) {
                    if (!Array.isArray(array[key])) {
                        if (root.hasOwnProperty(key) && isNaN(Number(key))) root[key].value = array[key]
                        else if (root.hasOwnProperty(key) && !isNaN(Number(key))) {root[key].value = array[key].value}
                    } else {
                        if (root[key]) {
                            fillData.bind(this)({array: array[key], root: root[key]})
                        }
                    }
                }
                this.kDataWatcherSettings.isFilled = true;
            }

            fillData.bind(this)({array: this.kDataWatcherSettings.savedState.data, root: this.dataState})
        }
    },
    created() {
        this.calculation.value = KDataWatcher.generateCalculationNumber();
        KDataWatcher.transfer({dbname: this.kDataWatcherSettings.dbname, storename: this.kDataWatcherSettings.storename, state: this.dataState})
    },
    watch: {
        dayDifferent(value) {
            if (value >= 0) {
                this.setStatus({days: value})
            }
        },
        dataComparisonEngine(value) {
            if (value) {
                this.calculation.value = KDataWatcher.generateCalculationNumber();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modalTipBody {
    padding: 20px;

}
</style>