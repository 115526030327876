import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { KValidate } from "../assets/js/kValidate.js";
import { RankoApi } from "../assets/js/rankoApi.js";
import { EBrokerApi } from "../assets/js/ebrokerApi.js";
import { KDataWatcher } from "../assets/js/KDataWatcher.js";
import { Creditworthiness } from "../assets/js/creditworthinessModule.js";

export default new Vuex.Store({
  state: {
    period: {name: 'period', question: 'Jak długo będziesz spłacał kredyt? ', value: 4, required: true, register: false, resetValue: 4,
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    loanPercent: {name: 'loanPercent', question: 'Ile wynosi oprocentowanie tego kredytu?', value: '5', required: true, register: false, resetValue: '5',
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill','afterDecimal'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']}, 
    otherLoans: {name: 'otherLoans', question: 'Raty innych kredytów', value: '', required: true, register: false, resetValue: '',
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    cardsLimits: {name: 'cardsLimits', question: 'Limity w kartach', value: '', required: true, register: false, resetValue: '',
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    expenses: {name: 'expenses', question: 'Wydatki domowe (czynsz, prąd, TV)', value: '', required: true, register: false, resetValue: '',
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    monthIncome: {name: 'monthIncome', question: 'Dochód netto', value: '', required: true, register: false, resetValue: '',
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    typeOfEmployment: {name: 'typeOfEmployment', question: 'Rodzaj zatrudnienia', value: '', required: true, register: false, resetValue: '',
      options: ['Umowa o pracę na czas nieokreślony', 'Umowa o pracę na czas określony','Działalność gospodarcza','Umowa o dzieło','Umowa zlecenie','Emerytura','Renta'], 
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    endOfContract: {name: 'endOfContract', question: 'Podaj datę końca umowy', value: '', required: true, register: false, resetValue: '',
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    companyPeriod: {name: 'companyPeriod', question: 'Jak długo prowdzisz działalność?', value: '', required: true, register: false, resetValue: '',
      options: ['Krócej niż 12 m-cy', 'Dłużej niż 12 m-cy'], 
      indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    continuityOfEmployment: {name: 'continuityOfEmployment', question: 'Czy jest ciągłość zatrudnienia', value: '', required: true, register: false, resetValue: '',
      options: [{label: 'Tak', value: 'Tak'}, {label: 'Nie', value: 'Nie'}], type: 'radio', indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], 
      validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    permaBenefit: {name: 'permaBenefit', question: 'Czy świadczenie jest przyznane na stałe?', value: '', required: true, register: false, resetValue: '',
      options: [{label: 'Tak', value: 'Tak'}, {label: 'Nie', value: 'Nie'}], type: 'radio', indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], 
      validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    peopleDependent: {name: 'peopleDependent', question: 'Liczba osób na utrzymaniu', value: '', required: true, register: false, resetValue: '',
      options: [{label: '0', value: '0'}, {label: '1', value: '1'},{label: '2', value: '2'},{label: '3', value: '3'},{label: '4', value: '4'},{label: '5+', value: '5'}], 
      type: 'radio', indexedDB: true, error: false, errorMethod: '', validMethod: ['isFill'], validationGroup: 'creditworthiness', rankomatApiKey: ['answers']},
    phoneNumber: {name: 'phoneNumber', question: 'Podaj swój numer telefonu', value: '', required: true, register: false, resetValue: '',
      error: false, errorMethod: '', validMethod: ['isFill', 'apiHLR'], validationGroup: 'creditworthiness', indexedDB: true, rankomatApiKey: ['phone']},
    personalAgreements: [ 
      {text: `Zaakceptuj wszystkie zgody`,
      name:'acceptAll', value: false, split: false, short: false, required: false, error: false, errorMethod: '', validMethod: ['isFill'], indexedDB: true},

      {text: `Oświadczam, że zapoznałem się i akceptuję regulaminy świadczenia usług przez Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.) oraz Ubezpieczenia Rankomat sp. z o.o. sp. k.`,
      name:'agreement_more_1',value:false, split: true, short: true, required: true, error: false, errorMethod: '', validMethod: ['isFill'], indexedDB: true},

      {text: `Wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem dla celów marketingu bezpośredniego, 
      w tym przy użyciu automatycznych systemów wywołujących przez Grupę Rankomat, w skład której wchodzą Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.) oraz Ubezpieczenia 
      Rankomat sp. z o.o. sp. k. zgodnie z art. 172 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. z 2017 r. poz. 1907).`,
      name:'agreement_more_2',value: false, split: true, short: true, required: true, error: false, errorMethod: '', validMethod: ['isFill'], indexedDB: true},

      {text: `Wyrażam dobrowolną zgodę na przetwarzanie przez Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.) moich danych osobowych w celach marketingu towarów lub
       usług podmiotów współpracujących z Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.), w tym poprzez profilowanie w celu poznania moich potrzeb i zainteresowań.`,
      name:'agreement_more_3',value: false, split: true, short: true, required: false, error: false, errorMethod: '', validMethod: ['isFill'], indexedDB: true},

      {text: `Wyrażam dobrowolną zgodę na otrzymywanie informacji handlowych drogą elektroniczną (za pośrednictwem wiadomości e-mail 
        oraz wiadomości SMS/MMS) od Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.) na podany w formularzu adres e-mail oraz numer telefonu. `, 
      name:'agreement_more_4',value: false, split: true, short: true, required: false, error: false, errorMethod: '', validMethod: ['isFill'], indexedDB: true},
    ],
    calculationNumber: {name: 'calculationNumber', question: 'Numer kalkulacji', value: '', indexedDB: true, rankomatApiKey: ['answers']},
    KDataWatcher: {
      dbname: 'KZK',
      storename: 'KZKstore',
      isFilled: false,
      savedState: {}
    },
    creditworthiness: null,
    rankomatApi: new RankoApi({
      productionURL: 'kalkulator-zdolnosci-kredytowej.pl',
      productID: 'finanse',
      formCode: 'Kalkulator_zdolnosci_kredytowej_pl_checkout',
      productCode: 'kalkulator_zdolnosci_kredytowej',
      agreementsKey: 'personalAgreements'
    }),
    recommendedOffers: []
  },
  getters: { 
    ebrokerApi(state) {
      return new EBrokerApi({
        email: '',
        mailingListUuid: 'b9b627f4-6140-46cc-869b-1eb41ff81505',
        yearOfBirth: "",
        phoneNumber: state.phoneNumber.value,
        agreements: state.personalAgreements,
        formName: 'kalkulator_zdolnosci_kredytowej_pl_checkout'
      })
    },
    K_DATA_WATCHER_CHANGE_CNUMBER(state) {
      var debug = false;
      if (Object.keys(state.KDataWatcher.savedState).length > 0 && state.KDataWatcher.isFilled) {
        var result = false;
        for (let key in state) {
          let item = (state[key] !== null && state[key].hasOwnProperty('indexedDB')) ? state[key] : false;
          if (item && key !== 'agreements') {
            let toCompare = state.KDataWatcher.savedState.data[key];
            if (item.value === toCompare) {
              if (debug) console.log(`(key: ${key}) item.value === toCompare: ${item.value} === ${toCompare}, Result: ${item.value === toCompare}`);
              result = true
            } else {
              if (debug) console.log(`(key: ${key}) item.value !== toCompare: ${item.value} !== ${toCompare}, Result: ${item.value === toCompare}`);
              result = false;
              break;
            }
          }
        }
        return !result
      }
    }
  },
  mutations: {
    K_DATA_WATCHER_CONTROLER(state,{transfer = false, fill = false}) {
      if (transfer) KDataWatcher.transfer({dbname: state.KDataWatcher.dbname, storename: state.KDataWatcher.storename, state});
      if (fill) {
        function fillData({array, root}) {
          for (let key in array) {
              if (!Array.isArray(array[key])) {
                  if (root.hasOwnProperty(key) && isNaN(Number(key))) root[key].value = array[key]
                  else if (root.hasOwnProperty(key) && !isNaN(Number(key))) {root[key].value = array[key].value}
              } else {
                  fillData({array: array[key], root: root[key]})
              }
          }
          state.KDataWatcher.isFilled = true;
        }
        fillData({array: state.KDataWatcher.savedState.data, root: state})
      }
    },
    SET_CREDITWORTHINESS(state, payload) {
      state.creditworthiness = payload
    }
  },
  actions: {
    getOffers(context) {
      return new Promise((resolve, reject) => {
        let url = `https://finanse.rankomat.pl/wp-admin/admin-ajax.php`
        let data = new URLSearchParams();
        let period = (Number(context.state.period.value)*12 > 120) ? 120 : Number(context.state.period.value)*12;
        data.append('action','get_credits_recommended');
        data.append('amount', 25000);
        data.append('period', period);
        let cfg = {
          method: 'POST',
          body: data
        };
  
        (async function() {
          let response = await fetch(url,cfg);

          if (response.status === 200) {
            let json = await response.json();
            json = json.sort((a,b) => {
              if (a.installment > b.installment) return 1
              if (a.installment < b.installment) return -1
              return 0
            })
            if (json.length > 3) json = json.slice(0,3);
            context.state.recommendedOffers = json;
            resolve({status: 'success'})
          } else {
            reject({status: 'failed'})
          }
        })()

      })
    },
    async validate_state(context, {group, agreementsKey}) {
      return await new KValidate({state: context.state, scrollToError: true, validationGroup: group, agreementsKey});
    },
    async validate(state, {item}) {
      return await new KValidate({item})
    },
    async sendData(context) {
      return new Promise((resolve) => {
        (async function(){
          await new KDataWatcher({dbname: context.state.KDataWatcher.dbname, storename: context.state.KDataWatcher.storename}).addData({state: context.state});
          resolve({success: true})
        })()
      })
    },
    countCreditworthiness(context) {
      let parseLoanPercent = context.state.loanPercent.value.replace(',','.');
      let creditworthiness = new Creditworthiness({
        flatStatus: 'Inne',
        otherLoans: true, 
        otherLoansInstallment: Number(context.state.otherLoans.value),
        card: true,
        cardLimit: Number(context.state.cardsLimits.value),
        paymentDeley: false, 
        moneySource: context.state.typeOfEmployment.value,
        endOfContractDate: context.state.endOfContract.value,
        companyPeriod: context.state.companyPeriod.value,
        continuityOfEmployment: context.state.continuityOfEmployment.value,
        permaBenefit: context.state.permaBenefit.value,
        birthday: '22-04-1990', 
        monthlyExpenses: Number(context.state.expenses.value), 
        peopleDependent: Number(context.state.peopleDependent.value), 
        monthIncome: Number(context.state.monthIncome.value),
        repaymentPeriod: Number(context.state.period.value),
        averageCreditInterestRate: (Number(parseLoanPercent) === 0) ? 0.01 : Number(parseLoanPercent)
      })
      context.commit('SET_CREDITWORTHINESS', creditworthiness)
    },
    sendDataRankoAPI(context) {
      return new Promise((resolve,reject) => {
        (async function(){
          let prepereData = await context.state.rankomatApi.prepereData({state: context.state});
          if (prepereData.status === 'success') {
            await context.state.rankomatApi.send();
            // console.log('sendData', sendData);
            resolve({success: true})
          } else {
            reject({success: false})
          }
        })()
      })
    }
  }
})