export class KDataWatcher {
    constructor({dbname, storename, indexBy = false, debug = false, agreementsKey = 'agreements', autoCreateDB = true}) {
        if (!('indexedDB' in window)) {
            console.log('This browser doesn\'t support IndexedDB');
            return;
        }
        this.request = null;
        this.db = null;
        this.store = null;
        this.index = null;
        this.tx = null;
        this.debug = debug;

        this.dbname = dbname;
        this.storename = storename;
        this.indexBy = indexBy;

        this.calculationNumber = null;
        this.agreementsKey = agreementsKey;

        if (autoCreateDB) this.createDB();
    }

    createDB() {
        window.indexedDB.deleteDatabase(this.dbname);

        this.request = window.indexedDB.open(this.dbname, 1);
        this.request.onupgradeneeded = () => {
            this.db = this.request.result;
            this.store = this.db.createObjectStore(this.storename, {autoIncrement: true});
            if (this.debug) console.log('Store created', this.store);
            if (this.indexBy) {
                this.index = this.store.createIndex(this.indexBy, this.indexBy, {unique: true});
                if (this.debug) console.log(`Index set for ${this.indexBy}`);
            }
        }

        if (this.debug) this.request.onerror = (e) => console.error('Error:', e.target.errorCode);
    }

    addData({state}) {
        return new Promise((resolve, reject) => {
            try {
                this.connectDB(function(){
                    this.insertData({state})
                }.bind(this))
                resolve({status: 'success'})
            }
            catch {
                reject({status:'failed'})
            }
        })
    }

    updateData({state}) {
        // for (let key in state) {
        //     let item = state[key];
        //     if (item !== null && item.hasOwnProperty('indexedDB') && item.indexedDB) {
        //         data[item.name] = item.value;
        //         this.store.data.put({})
        //     }
        //     if (key === this.agreementsKey) {
        //         let mappedAgreements = item.reduce((acc,item) => {
        //             acc.push({name: item.name, value: item.value})
        //             return acc
        //         }, [])
        //         data[key] = mappedAgreements
        //     }
        // }
        // this.store.put({data, date: new Date()});
    }

    connectDB(cb) {
        this.request.onsuccess = () => {
            this.db = this.request.result;
            if (this.debug) console.log('DB: ', this.db);
            this.tx = this.db.transaction(this.storename, "readwrite");
            this.store = this.tx.objectStore(this.storename);
            if (this.index) this.index = this.store.index(this.indexBy);

            this.db.error = (e) => {
                console.error(`Error: ${e.target.errorCode}`);
            }

            cb();
    
            this.db.close();
        }
    }

    insertData({state}) {
        let data = {};
        for (let key in state) {
            let item = state[key];
            if (item !== null && item.hasOwnProperty('indexedDB') && item.indexedDB) {
                data[item.name] = item.value;
            }
            if (key === this.agreementsKey) {
                let mappedAgreements = item.reduce((acc,item) => {
                    acc.push({name: item.name, value: item.value})
                    return acc
                }, [])
                data[key] = mappedAgreements
            }
        }
        this.store.put({data, date: new Date()});
    }

    static transfer({dbname, storename, state}) {
        let request = window.indexedDB.open(dbname)
        request.onsuccess = () => {
            let db = request.result;
            try {
                let tx = db.transaction(storename,"readonly"),
                    store = tx.objectStore(storename);

                let data = store.get(1);

                data.onsuccess = function() {
                    state.KDataWatcher.savedState = data.result;
                }

                tx.oncomplete = () => {
                    db.close();
                }
            }
            catch {
                window.indexedDB.deleteDatabase(dbname);
            }
        }
    }

    static getValue({dbname, storename, key}) {
        return new Promise((resolve, reject) => {

            let request = window.indexedDB.open(dbname)
            request.onsuccess = () => {
                let db = request.result;
                try {
                    let result;
                    let tx = db.transaction(storename,"readonly"),
                        store = tx.objectStore(storename);
    
                    let data = store.get(1);
    
                    data.onsuccess = function() {
                        let storedData = data.result.data;
                        let result = (storedData.hasOwnProperty(key)) ? storedData[key] : null
                        resolve(result)
    
                    }
    
                    tx.oncomplete = () => {
                        db.close();
                    }
                }
                catch {
                }
            }
        })
    }

    static removeDB({dbname}) {
        window.indexedDB.deleteDatabase(dbname);
    }

    static generateCalculationNumber() {
        var text = "";
        var numb = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var numbers = "0123456789";
        
        for (var i = 0; i < 4; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        for (var i = 0; i < 2; i++) {
            numb += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }

        return text + numb; 
    }

    static showData({dbname, storename}) {
        let request = window.indexedDB.open(dbname)
        request.onsuccess = () => {
            let db = request.result;
            try {
                let tx = db.transaction(storename,"readonly"),
                    store = tx.objectStore(storename);

                let data = store.get(1);

                data.onsuccess = function() {
                    console.log(data.result)
                }

                tx.oncomplete = () => {
                    db.close();
                }
            }
            catch {
            }
        }
    }
}