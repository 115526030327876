<template>
    <div class="body-wrapper">
        <div v-if="item.order_contact" class="green-frame">
            <p>Dziękujemy za złożenie zamówienia.</p>
            <p>Po weryfikacji, przekażemy twoje zamówienie Agentowi Ubezpieczeniowemu z Twojej okolicy.</p>
            <p>Agent przygotuje ofertę dopasowaną do Twoich indywidualnych cech i potrzeb.</p>
        </div>
        <div v-else class="summary-info">
            <singleQuestion :question="order_contact_modal.phone_number" :padding="'7px 0px'">
                <template slot="answer">
                    <span class="lIcon">+48</span>
                    <input class="prefixed" id="field-phone_number" type="number" v-model.trim.lazy="phone_number_value">
                </template>
            </singleQuestion>
            <agreements :singleErrors="true" :agreements="order_contact_modal.agreements" :agreementsErrorGetter="order_contact_modal_error" :hideTitle="true"></agreements>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations}                           from 'vuex'
import agreements                           from '../kAgreements.vue'
import singleQuestion                       from '../singleQuestion.vue'

export default {
    components: {
        agreements, singleQuestion
    },
    props: {
        item: {
            type: Object
        }
    },
    created() {
        this.NEW_PHONE_SYNC();
    },
    mounted() {
        var dl = {
            event: 'offerButton',
            eventCategory: 'Zamów rozmowę nnw',
            eventAction: this.item.company.name
        };
        
        dataLayer.push(dl);
    },
    computed: {
        ...mapState(['order_contact_modal']),
        ...mapGetters(['order_contact_modal_error']),
        phone_number_value: {
            get() {
                this.SET_PHONE_SAME_AS_IN_FORM();
                this.phoneValid({nested: 'order_contact_modal'}).then(response => {
                    if (response != undefined) {
                        if (!response.data.isValid) {
                            this.$store.state.order_contact_modal.phone_number.apiError = true;
                            this.$store.state.order_contact_modal.phone_number.error = true;
                            this.$store.state.order_contact_modal.phone_number.errorMethod = 'api_phone';
                        } else {
                            this.$store.state.order_contact_modal.phone_number.apiError = false;
                            this.$store.state.order_contact_modal.phone_number.error = false;
                            this.$store.state.order_contact_modal.phone_number.errorMethod = '';
                        }
                    }
                }, error => {
                    console.error(error)
                }).catch((error) => {})

                return this.$store.state.order_contact_modal.phone_number.value
            },
            set(value) {
                this.$store.state.order_contact_modal.phone_number.value = value
                this.SET_PHONE_SAME_AS_IN_RESULTS();
            }
        },
        isOrdered() {
            return this.item.order_contact
        },
        numbersAreTheSame() {
            return this.phone_number_value === this.order_contact_modal.savedPhoneNumber
        }
    },
    methods: {
        ...mapMutations(['SET_PHONE_SAME_AS_IN_FORM','SET_PHONE_SAME_AS_IN_RESULTS','NEW_PHONE_SYNC','TOGGLE_AGREEMENTS','SAVE_PHONE_NUMBER']),
        ...mapActions(['phoneValid']),
    },
    watch: {
        isOrdered(val) {
            this.SAVE_PHONE_NUMBER({phone_number: this.phone_number_value});
            if (val) {
                var dl = {
                    event: 'offerButton',
                    eventCategory: 'Zamów rozmowę nnw - wysłanie nr telefonu',
                    eventAction: this.item.company.name
                };
                
                dataLayer.push(dl);
            }

        },
        numbersAreTheSame(val) {
            if (val) {
                // console.log('ustawiam zgody na true');
                this.TOGGLE_AGREEMENTS({nested: 'order_contact_modal', setValue: true})
            } else {
                // console.log('ustawiam zgody na false');
                this.TOGGLE_AGREEMENTS({nested: 'order_contact_modal', setValue: false})
            }
        }
    }

    
}
</script>

<style lang="scss" scoped>
$xsmall: 400px;
$lightgreen: lightgreen;
$lightblue: lightblue;
.body-wrapper {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    color: #333;
    .summary-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px;

        input[type='number'] {
            &:focus {
                outline-width: 0px;
            }
            height: 34px;
            font-weight: 600;
            text-align: left;
            padding: 5px;
            border-radius: 4px;
            border: 1px solid #ddd;
            width: 300px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            appearance: none;
            &.prefixed {
                width: 220px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                @media screen and (max-width: $xsmall) {
                    width: calc(100% - 40px);
                }
            }
            
            @media screen and (max-width: $xsmall) {
                width: 100%;
            }
        }

        .lIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            color: #828282;
            font-size: 13px;
            font-weight: 400;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border: 1px solid #ddd;
            border-right: 0px;
            background: #f3f3f3;
        }
    }
    .adnotation {
        // border: 1px solid #e3e3e3;
        padding: 5px 10px;
        font-size: 10px;
        margin-bottom: 10px;
        background-color: $lightblue;
        text-align: left;
        .bold {
            font-weight: 600;
        }
    }
    .green-frame {
        padding: 10px 15px;
        font-weight: 600;
        color: rgb(82, 82, 82);
        // border: 1px solid #51942e;
        text-align: left;
        background: $lightgreen;
        p {
            margin-bottom: 10px;
        }
    }
}
</style>
