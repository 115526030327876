export class Creditworthiness {
    constructor({
        flatStatus,
        flatInstalment,
        flatRentInstalment,
        otherLoans, 
        otherLoansInstallment,
        card,
        cardLimit,
        paymentDeley, 
        moneySource,
        endOfContractDate,
        companyPeriod,
        continuityOfEmployment,
        permaBenefit,
        birthday, 
        monthlyExpenses, 
        peopleDependent, 
        monthIncome,
        repaymentPeriod,
        averageCreditInterestRate,
        debug = false,
        setMoneySourceOptions = {
            uop_n: 'Umowa o pracę na czas nieokreślony', // umowa o pracę na czas nieokreślony
            uop_o: 'Umowa o pracę na czas określony', // umowa o pracę na czas określony
            dg: 'Działalność gospodarcza', // działalność gospodarcza
            uod: 'Umowa o dzieło', // umowa o dzieło
            uz: 'Umowa zlecenie', // umowa zlecenie
            e: 'Emerytura', // emerytura
            r: 'Renta' // renta
        },
        setFlatStatusOptions = {
            dmzk: 'Posiadam dom/mieszkanie z kredytem hipotecznym', // dom/mieszkanie z kredytem
            dmbk: 'Posiadam dom/mieszkanie z kredytem hipotecznym', // dom/mieszkanie bez kredytu
            wdm: 'Wynajmuję dom/mieszkanie', // wynajem domu/mieszkania
            mur: 'Mieszkam u rodziców', // mieszkam u rodziców
            inne: 'Inne', // inne
        },
        setBusinessOptions = {
            wn12m: 'Dłużej niż 12 m-cy', // więcej niż 12 miesiecy
        },
        setContinuityOfEmploymentOptions = {
            yes: 'Tak', // Tak
        },
        setPermaBenefitOptions = {
            yes: 'Tak', // Tak
        }

    }) {
        this.debug = debug

        this.clientSettings = {
            flatStatus,
            flatInstalment,
            flatRentInstalment,
            otherLoans,
            otherLoansInstallment: Number(otherLoansInstallment),
            card,
            cardLimit: Number(cardLimit),
            paymentDeley,
            moneySource,
            endOfContractDate,
            companyPeriod,
            continuityOfEmployment,
            permaBenefit,
            birthday,
            monthlyExpenses: Number(monthlyExpenses),
            peopleDependent: Number(peopleDependent),
            monthIncome: Number(monthIncome)
        }

        this.creditworthinessSettings = {
            repaymentPeriod: Number(repaymentPeriod),
            averageCreditInterestRate,
            commission: 6,
            minimumAge: 7300, // days
            nationalAverage: 3500,
            presentDate: this.presentDate(),
        }

        this.fixedSettings = {
            deleyPoints: -100000,
        }

        this.moneySourceOptions = setMoneySourceOptions

        this.flatStatusOptions = setFlatStatusOptions

        this.businessOptions = setBusinessOptions

        this.continuityOfEmploymentOptions = setContinuityOfEmploymentOptions

        this.permaBenefitOptions = setPermaBenefitOptions
    }

    get parseFlatStatus() {
        if (this.clientSettings.flatStatus === this.flatStatusOptions.dmzk) return this.clientSettings.flatInstalment;
        if (this.clientSettings.flatStatus === this.flatStatusOptions.dmbk) return 0;
        if (this.clientSettings.flatStatus === this.flatStatusOptions.wdm) return this.clientSettings.flatRentInstalment;
        if (this.clientSettings.flatStatus === this.flatStatusOptions.mur) return 0;
        if (this.clientSettings.flatStatus === this.flatStatusOptions.inne) return 0;
    }

    get parseOtherLoans() {
        return (this.clientSettings.otherLoans) ? this.clientSettings.otherLoansInstallment : 0
    }

    get parseCard() {
        return (this.clientSettings.card) ? this.clientSettings.cardLimit : 0
    }

    get parsePaymentDeley() {
        return (this.clientSettings.paymentDeley) ? this.fixedSettings.deleyPoints : 0
    }
    
    get parseMoneySource() {
        if (this.clientSettings.moneySource === this.moneySourceOptions.uop_n) return 1;
        if (this.clientSettings.moneySource === this.moneySourceOptions.uop_o) return this.endOfContractTest();
        if (this.clientSettings.moneySource === this.moneySourceOptions.dg) return this.businessTest();
        if (this.clientSettings.moneySource === this.moneySourceOptions.uod) return this.continuityOfEmploymentTest();
        if (this.clientSettings.moneySource === this.moneySourceOptions.uz) return this.continuityOfEmploymentTest();
        if (this.clientSettings.moneySource === this.moneySourceOptions.e) return 1;
        if (this.clientSettings.moneySource === this.moneySourceOptions.r) return this.permaBenefitTest();
    }

    get parseBirthday() {
        let [dd, mm, yyyy] = String(this.clientSettings.birthday).replace(' ','').split('-');
        let birthdayDate = new Date(Number(yyyy),Number(mm),Number(dd));
        return (Math.floor(this.daysDiff(birthdayDate, 'today')) > this.creditworthinessSettings.minimumAge) ? 1 : 0;
    }

    get parseEndOfContractTime() {
        if (this.clientSettings.endOfContractDate) {
            let [dd, mm, yyyy] = String(this.clientSettings.endOfContractDate).replace(' ','').split('-');
            return Math.floor(this.daysDiff('today', new Date(Number(yyyy),Number(mm),Number(dd)))/30)
        }
        return 0
    }

    get creditable() {
        return (this.parseMoneySource + this.parseBirthday === 2)
    }

    get conclusions() {
        return {
            maxPay: this.maxPayTest(),
            expenses: this.expensesTest(),
            possibleInstalment: this.possibleInstalmentTest(),
            availableCredit: this.availableCreditTest()
        }
    }

    get maxCredit() {
        let result = (this.conclusions.availableCredit/(1+(this.creditworthinessSettings.commission/100)))-this.parseCard+this.parsePaymentDeley;
        if (isNaN(result) || result < 0) {result = 0}
        // result = (result >= 255550) ? 255550 : result;

        return result
    }

    get parseRepaymentPeriod() {
        let result = (this.clientSettings.moneySource === this.moneySourceOptions.uop_o) ? this.parseEndOfContractTime : this.creditworthinessSettings.repaymentPeriod * 12;
        return result
    }

    get creditworthiness() {
        return (this.creditable) ? this.maxCredit : 0
    }

    daysDiff(startDate,endDate) {
        if (startDate === 'today') {startDate = this.creditworthinessSettings.presentDate} else {startDate.setMonth(startDate.getMonth() - 1)}
        if (endDate === 'today') {endDate = this.creditworthinessSettings.presentDate} else {endDate.setMonth(endDate.getMonth() - 1)}
    
        var bigger = (startDate.getTime() > endDate.getTime()) ? startDate : endDate;
        var smaller = (startDate.getTime() != bigger.getTime()) ? startDate : endDate;
        let result = Math.round((bigger.getTime()-smaller.getTime())/(1000*60*60*24))
        return result
    }

    presentDate() {
        return new Date();
    }

    endOfContractTest() {
        let result = (this.parseEndOfContractTime > 12) ? 1 : 0;
        return result
    }

    businessTest() {
        return (this.clientSettings.companyPeriod === this.businessOptions.wn12m) ? 1 : 0
    }

    continuityOfEmploymentTest() {
        return (this.clientSettings.continuityOfEmployment === this.continuityOfEmploymentOptions.yes) ? 1 : 0
    }

    permaBenefitTest() {
        let result = (this.clientSettings.permaBenefit === this.permaBenefitOptions.yes) ? 1 : 0
        return result
    }

    maxPayTest() {
        let result = (this.clientSettings.monthIncome > this.creditworthinessSettings.nationalAverage) 
        ? this.clientSettings.monthIncome * 0.65 
        : this.clientSettings.monthIncome * 0.5;

        if (this.debug) console.log(`Maksymalnie na raty może płacić (maxPayTest) ${result}`);

        return result;
    }

    expensesTest() {
        let result = this.parseFlatStatus + this.parseOtherLoans + this.clientSettings.monthlyExpenses + this.clientSettings.peopleDependent * 300
        if (this.debug) console.log(`Tyle wydaje (expensesTest) ${result}`);
        return result
    }

    possibleInstalmentTest() {
        let possibleInstallmentCondition = (this.expensesTest() < this.clientSettings.monthIncome) ? (this.maxPayTest() - this.parseFlatStatus - this.parseOtherLoans) : 0
        let possibleInstallmentExpenses = this.clientSettings.monthIncome - this.expensesTest()
        let result = (possibleInstallmentExpenses < possibleInstallmentCondition) ? possibleInstallmentExpenses : possibleInstallmentCondition

        if (this.debug) console.log(`Możliwa rata z warunku (possibleInstallmentCondition): ${possibleInstallmentCondition} \n
                    Możliwa rata z wydatków (possibleInstallmentExpenses): ${possibleInstallmentExpenses} \n
                    Rata klienta (possibleInstalmentTest) ${result}`);

        return result
    }
    
    availableCreditTest() {
        let counter = this.possibleInstalmentTest()*(Math.pow((1+(this.creditworthinessSettings.averageCreditInterestRate/100)/12), this.parseRepaymentPeriod)-1)
        let denominator = Math.pow((1+(this.creditworthinessSettings.averageCreditInterestRate/100)/12),this.parseRepaymentPeriod)*((1+(this.creditworthinessSettings.averageCreditInterestRate/100)/12)-1)
        // console.log(`Math.pow((1+(${this.creditworthinessSettings.commission}/100)/12),${this.parseRepaymentPeriod})*((1+(${this.creditworthinessSettings.averageCreditInterestRate}/100)/12)-1)`);
        let result = counter/denominator

        if (this.debug) console.log(`Dostępna kwota kredytu (availableCreditTest): ${result} \n
                    Licznik: ${counter} \n
                    Mianownik: ${denominator}`);

        return result
    }

}