<template>
    <div class="detail-row">
        <div class="desc" :class="{'desc-bold': dBold}">{{desc}} </div>
        <div v-if="short && !showMore" class="value" :class="{'value-bold': vBold}">
            {{value_par | short(30)}}...
            <span class="more" @click="showMore = !showMore">Rozwiń</span>
        </div>
        <div v-else class="value" :class="{'value-bold': vBold}">
            <template v-if="value_par === 'success'">
                <div  data-v-fe28c5ba="" class="success fa fa-check-circle fa-lg"></div>
            </template>
            <template v-else-if="value_par === 'loss'">
                <div  data-v-fe28c5ba="" class="fail fa fa-plus-circle fa-lg"></div>
            </template>
            <template v-else-if="link !== ''">
                <a :href="link" target="_blank">pobierz</a>
            </template>
             <!-- <template v-else-if="html">
                 <span v-html="value_par"></span>
            </template> -->
            <template v-else>
                <span v-html="value_par"></span>
            </template>
            <span class="more" v-if="showMore" @click="showMore = !showMore">Zwiń</span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            showMore: false
        }
    },
    props: {
        desc: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Boolean],
            default: ''
        },
        dBold: {
            type: Boolean,
            default: false
        },
        vBold: {
            type: Boolean,
            default: false
        },
        short: {
            type: Boolean,
            default: false
        },
        unit: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: ''
        },
        filterBy: {
            type: String,
            default: ''
        },
        html: {
            type: Boolean,
            default: false
        },
        convertToIcons: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        value_par() {
            let true_array = [true, '1'];
            let false_array = [false, '0'];

            if (this.convertToIcons && true_array.indexOf(this.value) != -1) {return 'success'}
            else if (this.convertToIcons && false_array.indexOf(this.value) != -1) {return 'loss'}
            else if (this.unit) {return this.value + ' ' + this.unit}
            else if (this.filterBy) {return this.$options.filters[this.filterBy](this.value)}
            return this.value;
        }
    },
    filters: {
        short(value, howShort) {
            return value.split(' ').slice(0,howShort).join(' ');
        }
    }
}
</script>

<style lang='scss'>
$small: 450px;
$blue: red;
.detail-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed #e9e9e9;
    font-size: 13px;
    .desc {
        flex: 1;
        border-right: 1px dashed #e9e9e9;
        padding: 8px 19px;
        color: #3a3a3a;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        
        @media screen and (max-width: $small) {
            padding: 8px 5px;
            flex: 0 0 50%;
        }
    }
    .value {
        padding: 10px;
        flex: 0 0 50%;
        padding: 8px 19px;
        color: #3a3a3a;
        text-align: left;
        pre {
            font-size: 13px;
            font-family: 'Montserrat', sans-serif;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap !important;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
            margin-bottom: 0px
        }
    }
    .desc-bold {
        font-size: 14px;
        font-weight: 600;
    }
    .value-bold {
        color: #012174;
        font-weight: 600;
    }
    .more {
        color: $blue;
        cursor: pointer;
    }
    .rank-left {
        display: table;
        &:before {
            content: '';
            height: 20px;
            width: 20px;
            background-size: cover;
        }
    }
    .success {
        color: #6bcd44;
        font-size: 18px;
    }
    .fail {
        color: #ff4342;
        font-size: 18px;
        transform: rotate(45deg);
    }
}
</style>




