<template>
    <div class="kCalendar-wrapper" ref="calendar"  :id="'kCalendar-' + name">
        <div class="kCalendar-selects">
            <div class="prev" @click="prev()"></div>
            <select class="select" name="kMonth" id="kMonth" v-model="selected.month">
                <option v-for="n in 12" :value="n" :key="n + '_month'">{{language.months[n-1]}}</option>
            </select>
            <select class="select" name="kYears" id="kYears" v-model="selected.year">
                <option v-for="year in yearsArray" :value="year" :key="year + '_year'">{{year}}</option>
            </select>
            <div class="next" @click="next()"></div>
        </div>
        <div class="labels">
            <div v-for="label in language.labels" :key="label + '_label'" class="label">{{label}}</div>
        </div>
        <div class="days-wrapper">
            <day v-for="empty in emptyBoxes" :key="empty + '_empty'" class="day" :day="empty" :disabled="true"></day>
            <day v-for="day in daysArray" 
                :key="day + '_day'" 
                :selectedDay="selected.day" 
                :day="day" 
                :rangeStart="rangeStart"
                :rangeEnd="rangeEnd"
                :save="save"
                :selected="selected"
                @setDay="setDay" 
                @close="close">
            </day>
            <day v-for="day in nextMonth" :key="day + '_nextMonthDay'" :day="day" :disabled="true">{{day}}</day>
        </div>
    </div>
</template>

<script>
import day from './kDays.vue';

export default {
    components: {
        day
    },
    props: {
        name: {
            type: String,
            default: 'kCalendar'
        },
        rangeStart: {
            type: Object,
            require: true
        },
        rangeEnd: {
            type: Object,
            require: true
        },
        selected: {
            type: Object
        },
        save: {
            type: Object
        }
    },
    mounted() {
        document.addEventListener('click', this.outsideClose)
    },
    destroyed() {
        document.removeEventListener('click', this.outsideClose)
    },
    data() {
        return {
            language: {
                labels: ['pon','wt','śro','cz','pt','sob','nd'],
                days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
                months: ['Styczeń','Luty','Marzec','Kwiecień','Maj','Czerwiec','Lipiec','Sierpień','Wrzesień','Październik','Listopad','Grudzień']
            }
        }
    },
    methods: {
        fillDays(ddays, ddaysArr){
            for (var i = 1; i <= ddays; i++) {
                ddaysArr.push(i)
            }
        },
        setDay(payload) {
            this.$set(this.selected,'day',payload);
            this.$emit('setDateFromCalendar', this.selected);
        },
        close() {
            this.$emit('close');
        },
        outsideClose(e) {
            var el = document.querySelector(`#kCalendar-${this.name}`).parentElement;
            var target = e.target;
            if ( el !== target && !el.contains(target)) {
                this.$emit('close');
            }
        },
        prev() {
            if (this.selected.year === this.rangeStart.year) {
                if (this.selected.month > 1) {
                    this.selected.month--
                }
            } else {
                if (this.selected.month > 1) {
                    this.selected.month--
                } else {
                    this.selected.month = 12;
                    this.selected.year--
                }
            }
        },
        next() {
            if (this.selected.year === this.rangeEnd.year) {
                if (this.selected.month < 12) {
                    this.selected.month++
                }
            } else {
                if (this.selected.month < 12) {
                    this.selected.month++
                } else {
                    this.selected.month = 1;
                    this.selected.year++
                } 
            }
        }
    },
    computed: {
        yearsArray() {
            var start = this.rangeStart.year;
            var stop = this.rangeEnd.year;
            var diff = stop - start;
            var yearsArray = [start];
            for (var i = 1; i <= diff; i++) {yearsArray.push(start+i)}
            return yearsArray;
        },
        daysArray() {
            var daysArray = [];
            if (this.selected.month != null) {
                if (this.selected.month === 2) {
                    if (this.selected.year % 4 != 0) {this.fillDays(28,daysArray)} else {this.fillDays(29,daysArray)}
                } else {
                    if (this.selected.month < 8) {
                        if (this.selected.month % 2 != 0) {this.fillDays(31,daysArray)} else {this.fillDays(30,daysArray)}
                    } else {
                        if (this.selected.month % 2 == 0) {this.fillDays(31,daysArray)} else {this.fillDays(30,daysArray)}
                    }
                }
            }
            return daysArray;
        },
        dayIndex() {
            return new Date(this.selected.year,this.selected.month-1,1).getDay();
        },
        emptyBoxes() {
            var fromMonday = this.dayIndex - 1;
            return (fromMonday != -1) ? fromMonday : 6;
        },
        nextMonth() {
            var days = 7 - ((this.emptyBoxes + this.daysArray.length) % 7);
            return (days === 7) ? 0 : days;
        }
    }
}
</script>

<style lang="scss" scoped>
.kCalendar-wrapper {
    border: 1px solid #e7e7e7;
    padding: 15px 10px;
    border-radius: 4px;
    width: 260px;
    box-sizing: border-box;
    font-family: "Montserrat", Helvetica Neue, Arial, sans-serif;
    position: absolute;
    z-index: 1000;
    background-color: white;
    top: 48px;
    left: 0px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

    .kCalendar-selects {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        .select { 
            padding: 4px;
            border-radius: 4px;
            margin: 0px 5px;
            margin-bottom: 0;
            padding: 0 5px;
            height: 2rem;
            font-size: 12px;
            position: relative;
            font-weight: normal;
            // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: none;
            // border-color: #bee1ab;
            border-color: white;
            background-color: white;
            font-weight: 600;
            color: #012174;
            &:hover {
                background-color: rgb(245, 245, 245);
            }
        }
        .prev {
            // background: grey;
            height: 10px;
            width: 20px;
            position: relative;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                display: block;
                margin: 0 auto;
                top: 2px;
                left: 0;
                right: 0;
                height: 40%;
                width: 2px;
                background-color: #b1b1b1;
                border-radius: 10px;
                transform: skew(-40deg);
                
            }
            &::before {
                content: '';
                position: absolute;
                display: block;
                margin: 0 auto;
                bottom:0;
                left: 0;
                right: 0;
                height: 40%;
                width: 2px;
                background-color: #b1b1b1;
                border-radius: 10px;
                transform: skew(40deg);
            }
        }
        .next {
            @extend .prev;
            transform: scaleX(-1);
        }
    }
    .labels {
        margin-bottom: 10px;

        .label {
            display: inline-flex;
            justify-content: center;
            color: #b1b1b1;
            font-weight: 400;
            width: 34px;
            padding: 5px 0;
            font-size: 13px;
            margin-top: 5px;
            background: white;

        }
    }
    .days-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>

