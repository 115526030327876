<template>
    <div class="offerbox__wrapper">
        <div class="offerbox__row offerbox__row--top">
            <div class="offerbox__column offerbox__column--top offerbox__logo">
                <img v-if="item.nazwa_banku.logo" :src="`${item.nazwa_banku.logo.data.full_url}`" alt="">
            </div>
            <div class="offerbox__column offerbox__column--top offerbox__amount">
                <div class="offerbox__description">Całkowita kwota:</div>
                <div class="offerbox__value">{{item.total_return_cost | beautifyNumbers}} zł</div>
            </div>
            <div class="offerbox__column offerbox__column--top offerbox__commision">
                <div class="offerbox__description">Prowizja:</div>
                <div class="offerbox__value">{{Number(item.prowizja)*100 | beautifyNumbers}} %</div>
            </div>
            <div class="offerbox__column offerbox__column--top offerbox__rrso">
                <div class="offerbox__description">RRSO:</div>
                <div class="offerbox__value">{{Number(item.rrso)*100 | beautifyNumbers}} %</div>
            </div>
            <div class="offerbox__column offerbox__column--top offerbox__installment">
                <div class="offerbox__description">Miesięczna rata</div>
                <div class="offerbox__value">{{item.installment | beautifyNumbers}} zł</div>
            </div>
            <div class="offerbox__column offerbox__column--top offerbox__cta">
                <div class="offerbox__description">
                    <a :href="parse_link">
                        <div class="offerbox__button">Wybierz</div>
                    </a>
                </div>
            </div>
        </div>
        <div class="offerbox__row offerbox__row--bottom">
            <div class="offerbox__column offerbox__info"><div aria-hidden="true" class="fa fa-money offerbox__icon"></div>{{item.nazwa_banku.nazwa_banku}} {{item.nazwa_produktu}}</div>
            <div class="offerbox__column offerbox__details" @click="showDetails = true"><div aria-hidden="true" class="fa fa fa-plus offerbox__icon"></div>Szczegóły oferty</div>
            <div class="offerbox__column offerbox__compare"></div>
        </div>
        <div class="offerbox__row offerbox__button-wrapper--mobile">
            <a :href="parse_link">
                <div class="offerbox__button">Wybierz</div>
            </a>
        </div>

        <modalWrapper v-if="showDetails" @close="showDetails=false" :maxWidth="'990px'">
            <template slot="title">Szczegóły oferty (Oferta nr: {{item.offer_id}})</template>
            <template slot="content">
                <modalDetails :item="item" :period="period"></modalDetails>
            </template>
        </modalWrapper>

    </div>
</template>

<script>
import modalWrapper from './modalWrapper';
import modalDetails from './details/details';

export default {
    components: {
        modalWrapper, modalDetails
    },
    computed: {
        parse_link() {
            return `https://finanse.rankomat.pl/kredyty/kalkulator-gotowkowy/wniosek/?scoretype=cash&amount=${Number(this.amount)}&period=${this.periodParse}&id=${this.item.id}&ctype=cash`;
        },
        convertToMonth() {
            return (this.periodToMonth) ? 12 : 1
        },
        periodParse() {
            const periodMonth = Number(this.period)*this.convertToMonth
            return (periodMonth > 120) ? 120 : periodMonth
        }
    },
    props: {
        item: {
            required: true,
            type: Object
        },
        period: {
            required: true
        },
        amount: {
            default: 25000,
            type: Number
        },
        periodToMonth: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showDetails: false
        }
    },
    filters: {
        beautifyNumbers(value) {
            let twoDecimalPlaces = Number(value).toFixed(2)
            let dotToComa = String(twoDecimalPlaces).replace('.',',');
            if (dotToComa.indexOf(',00') > -1) dotToComa = dotToComa.replace(',00','')
            let polished = dotToComa.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return polished
        }
    }
}
</script>

<style lang="scss">
.offerbox__wrapper {
    background-color: #fff;
    box-shadow: 0 0 20px #e1e1e1;
    margin: 15px 0 0;
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Montserrat,sans-serif!important;
    .offerbox__row {
        display: flex;
        flex-wrap: wrap;
        &--top {
            border-bottom: 1px solid #e7e7e7;
        }
        &--bottom {
            padding: 10px 25px;
        }
        .offerbox__column {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-sizing: content-box;
            &:last-child {
                border-right: 0px;
            }

            &--top {
                border-right: 1px solid #e7e7e7;
                height: 100px;
                padding: 10px;
            }

            .offerbox__description {
                font-size: 13px;
            }

            .offerbox__value {
                color: #012174;
                font-weight: 700;
                font-size: 18px;
            }
        }

        .offerbox__logo {
            img {
                display: block;
                width: 130px;
                height: auto;
                margin: 0 auto;
            }
        }

        .offerbox__installment {
            border-right: 0px;
            .offerbox__description {
                order: 1;
                font-size: 14px;
            }
            .offerbox__value {
                font-size: 24px;
            }
        }

        .offerbox__info, .offerbox__details {
            color: #20a3dd;
            font-weight: 400;
            font-size: 13px;
            flex-direction: row;
            line-height: 1;
            // padding: 0px 15px;
        }

        .offerbox__details {
            cursor: pointer;
        }

        .offerbox__info {
            justify-content: flex-start;
            @media screen and (max-width: 750px) {
                display: none;
            }
            
        }
        .offerbox__compare {
            @media screen and (max-width: 750px) {
                display: none;
            }
        }

        .offerbox__icon {
            margin-right: 10px;
        }

        &.offerbox__button-wrapper--mobile {
            display: none;
        }
    }

    .offerbox__button {
        margin: 5px auto 0;
        display: block;
        padding: 15px 30px;
        border: 0;
        text-decoration: none;
        background-color: #20a3dd;
        border-radius: 50px;
        border-bottom: 2px solid #1a82b0;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        letter-spacing: 1px;
        transition: all .1s linear;
    }

}

.offerbox__wrapper { 
    @media screen and (max-width: 994px) {
        .offerbox__row {
            .offerbox__column {
                &--top {
                    border-bottom: 1px solid #e7e7e7;
                }
            }
            .offerbox__logo, .offerbox__installment {
                flex-basis: 50%;
                box-sizing: border-box;
            }
            .offerbox__logo {
                order: 0;
                border-right: 0px;

            }
            .offerbox__installment {
                order: 1;
            }
            .offerbox__amount {
                order: 2;
            }
            .offerbox__commision {
                order: 3;
            }
            .offerbox__rrso {
                order: 4;
                border-right: 0px;

            }
            .offerbox__cta {
                display: none;
                // order: 5;
                // flex-basis: 100%;
            }
            &.offerbox__button-wrapper--mobile {
                background-color: #f3f4f2;
                display: flex;
                padding: 5px 0px 10px;
                justify-content: center;;
                .offerbox__button {
                    background-color: #6bcd44;
                    border-bottom: 2px solid #52b131;
                }
            }
            &--top {
                border-bottom: 0px;
            }
        }
    }
}

.offerbox__wrapper { 
    @media screen and (max-width: 450px) {
        .offerbox__row {
            .offerbox__column {
                .offerbox__description {
                    text-align: center;;
                }
                .offerbox__value {
                    font-size: 14px;
                }
            }
            .offerbox__installment {
                .offerbox__value {
                    font-size: 22px;
                }
            }
        }
    }
}


</style>

