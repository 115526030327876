export class EBrokerApi {
    constructor({email = '', mailingListUuid = '', gender = '', yearOfBirth = '', phoneNumber = '', formName = '', agreements = []}) {
        this.state = {
            email,
            mailingListUuid,
            agreements: this.mapAgreements(agreements),
            fields: {
                gender: (gender !== '') ? this.genderParser(gender) : '',
                yearOfBirth,
                phoneNumber: (phoneNumber) ? `48${phoneNumber}` : '',
                formName
            }
        }
        if (email === '') this.fetchEmptyEmail();
    }

    mapAgreements(agreements) {
        return agreements.reduce((acc, agreement) => {
            let arr = acc;
            arr.push({
                content: agreement.text,
                type: (agreement.hasOwnProperty('type')) ? agreement.type : 'MARKETING',
                isRequired: agreement.required,
                isAccepted: agreement.value
            })
            return arr;
        },[])
    }

    timestamp() {
        let randomNumber = Math.floor(Math.random() * 1000);
        return `${Date.now()}${randomNumber}`;
    }

    fetchEmptyEmail() {
        this.state.email = `${this.timestamp()}@mock.pl`;
    }

    sendData() {
        return new Promise((resolve, reject) => {
            let url = 'https://utils.rankolabs.net/ebroker-api/subscribe/';
            let cfg = {
                method: 'POST',
                body: JSON.stringify(this.state)
            }

            fetch(url, cfg)
            .then((response) => {
                resolve(true)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    genderParser(value) {
        return (value === '1') ? 'M' : 'K'
    }

}