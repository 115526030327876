<template>
    <div class="buttons-wrapper" :class="{'buttons-position-right': buttonPosition === 'right'}">
        <button @click="active()" class="btn btn-next" :class="{'btn-next--green': color === 'green'}">
            
            <i v-if="loading" class="fa fa-circle-o-notch fa-spin fa-fw"></i>
            <slot name="rightButton"></slot>
            <i class="fa fa-fw fa-arrow-right" v-if="!loading && !hideArrow"></i> 
        </button>
    </div>
</template>

<script>
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'

export default {
    data() {
        return {
            loading: false
        }
    },
    props: {
        showPrev: {
            type: [Boolean, String],
            default: true
        },
        buttonPosition: {
            type: String,
            default: 'left'
        },
        onClick: {
            type: Function,
            required: true
        },
        spinner: {
            type: Boolean,
            default: false
        },
        color: {
            default: 'default',
            type: String
        },
        hideArrow: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        active() {
            let self = this;
            this.loading = true;
            this.onClick(function() {
                self.loading = false
            });

        }
    }
}
</script>

<style lang='scss' scoped>

$blue: #20a3dd;
$small: 640px;
$xsmall: 420px;

.buttons-wrapper {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #F3F4F2;
    box-shadow: 0 8px 20px rgba(225, 225, 225, 0.7);
    

    @media screen and (max-width: $xsmall) {
        justify-content: center;
    }

    &.buttons-position-right {
        justify-content: flex-end;
    }
}

.btn {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    padding: 15px 20px;
    border-radius: 50px;
    font-size: 14px;
    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.btn-prev {
    order: 1;
    background: $blue;
    border-bottom: 2px solid #1982B1;
    color: white;
    @media screen and (max-width: $small) {
        margin-top: 10px;
        order: 3;
        width: 100%
    }
}

.btn-next {
    // order: 2;
    background: $blue;
    border-bottom: 2px solid #1982B1;
    @media screen and (max-width: $small) {
        width: 100%
    }
    &--green {
        background-color: #33a574;
        border-bottom: 2px solid #28825b;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        letter-spacing: 1.5px;
    }
}
</style>


