<template>
    <div class="creditworthiness-wrapper">
        <section class="form-wrapper">
            <div class="title">
                <h2 class="text">
                    Powiedz nam coś więcej o kredycie, którego szukasz:
                </h2>
            </div>
            <kDataWatcher 
                :ask="true" 
                :askAfter="1"
                :dataState="$store.state"
                :kDataWatcherSettings="KDataWatcher"
                :calculation="calculationNumber"
            ></kDataWatcher>
            <singleQuestion :key="period.name" :question="period" :qTextCenter="true" :padding="'15px 10px 0px'" :additionalText="'Podaj okres w latach'">
                <template #answer>
                    <kSlider v-model="period.value" 
                        :header="''"
                        :min="1"
                        :max="35"
                        :unit="`<div class='far fa-clock'></div>`"
                        :labels="[{label:'od 1 roku'},{label: 'do 35 lat'}]"
                        :interval="1"
                        ></kSlider>
                </template>
            </singleQuestion> 
            <singleQuestion :key="loanPercent.name" :question="loanPercent" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kInput v-model="loanPercent.value" :allow="'numbers'" :icon="'%'" :iconPosition="'right'" :allowExpand="[110, 188, 190]" :lazy="true"></kInput>
                </template>
            </singleQuestion> 
            <div class="title">
                <h2 class="text">
                    Podaj nam sumę swoich wydatków
                </h2>
            </div>
            <singleQuestion :key="otherLoans.name" :question="otherLoans" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kInput v-model="otherLoans.value" :allow="'numbers'" :icon="'zł'" :iconPosition="'right'" :lazy="true"></kInput>
                </template>
            </singleQuestion>
            <singleQuestion :key="cardsLimits.name" :question="cardsLimits" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kInput v-model="cardsLimits.value" :allow="'numbers'" :icon="'zł'" :iconPosition="'right'" :lazy="true"></kInput>
                </template>
            </singleQuestion>
            <singleQuestion :key="expenses.name" :question="expenses" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kInput v-model="expenses.value" :allow="'numbers'" :icon="'zł'" :iconPosition="'right'" :lazy="true"></kInput>
                </template>
            </singleQuestion>
            <div class="title">
                <h2 class="text">
                    Twoja aktualna sytuacja finansowa
                </h2>
            </div>
            <singleQuestion :key="monthIncome.name" :question="monthIncome" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kInput v-model="monthIncome.value" :allow="'numbers'" :icon="'zł'" :iconPosition="'right'" :lazy="true"></kInput>
                </template>
            </singleQuestion>
            <singleQuestion :key="typeOfEmployment.name" :question="typeOfEmployment" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kSelect :name="typeOfEmployment.name" v-model="typeOfEmployment.value" :items="typeOfEmployment.options" 
                            :valuesameaslabel="true" :search="false" :disableSorting="true"></kSelect>
                </template>
            </singleQuestion>
            <singleQuestion v-if="typeOfEmployment.value === 'Umowa o pracę na czas określony'" :key="endOfContract.name" :question="endOfContract" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kDatepicker :range="EOCRange" :openDate="EOCOpenDate" v-model.lazy="endOfContract.value" :userYears="EOCAge"></kDatepicker>
                </template>
            </singleQuestion> 
            <singleQuestion v-if="typeOfEmployment.value === 'Działalność gospodarcza'" :key="companyPeriod.name" :question="companyPeriod" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kSelect :name="companyPeriod.name" v-model="companyPeriod.value" :items="companyPeriod.options" :valuesameaslabel="true" :search="false"></kSelect>
                </template>
            </singleQuestion> 
            <singleQuestion v-if="typeOfEmployment.value === 'Umowa o dzieło' || typeOfEmployment.value === 'Umowa zlecenie'" :key="continuityOfEmployment.name" :question="continuityOfEmployment" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kRadio v-for="(option, index) in continuityOfEmployment.options" :key="index + '_continuityOfEmployment'" v-model="continuityOfEmployment.value" 
                            :label="option.label" :setValue="option.value">
                    </kRadio>
                </template>
            </singleQuestion> 
            <singleQuestion v-if="typeOfEmployment.value === 'Renta'" :key="permaBenefit.name" :question="permaBenefit" :padding="'15px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kRadio v-for="(option, index) in permaBenefit.options" :key="index + '_permaBenefit'" v-model="permaBenefit.value" 
                            :label="option.label" :setValue="option.value">
                    </kRadio>
                </template>
            </singleQuestion> 
            <singleQuestion :key="peopleDependent.name" :question="peopleDependent" :padding="'10px 10px 0px'" :qTextCenter="true">
                <template #answer>
                    <kRadio v-for="(option, index) in peopleDependent.options" :key="index + '_peopleDependent'" v-model="peopleDependent.value" 
                            :label="option.label" :setValue="option.value">
                    </kRadio>
                </template>
            </singleQuestion> 
            <singleQuestion :key="phoneNumber.name" :question="phoneNumber" :padding="'15px 10px 30px'" :qCustomMargin="'15px 0px 0px'" :tip="true" :tipTitle="'Numer telefonu'">
                <template #answer>
                    <kInput v-model="phoneNumber.value" :allow="'numbers'" :lazy="true" :icon="'+48'" :iconPosition="'left'"></kInput>
                </template>
                <template #tip>
                    Dzięki tej informacji będziemy się mogli z Tobą skontaktować
                </template>
                <template #information>
                    <div class="blueBox">
                        Dzięki tej informacji będziemy mogli się z Tobą skontaktować i bezpłatnie zbadać Twoją zdolność kredytową
                    </div>
                </template>
            </singleQuestion> 
            <kAgreements :name="'personalAgreements'" :singleErrors="true" :agreements="personalAgreements" :padding="'18px 10px'"></kAgreements>
            <information></information>
            <navButtons :showPrev="false" :buttonPosition="'right'" :onClick="generate">
                <template slot="rightButton">Oblicz zdolność</template>
            </navButtons>
        </section>

        <section class="form-wrapper" v-if="creditworthiness">
            <creditworthinessResult></creditworthinessResult>
            <div class="thank-you-wrapper">
                <div>Dziękujemy za skorzystanie z formularza kontaktowego</div>
                <div>Nasz konsultant wkrótce się z Tobą skontaktuje</div>
            </div>
        </section>

        <section class="offers" v-if="creditworthiness">
            <div class="recommended-header">Rekomendowane dla Ciebie</div>
            <div class="recommended-information" v-if="Number(period.value) >= 10">Przedstawione oferty dotyczą kwoty kredytu: 25000 zł oraz okresu spłaty 10 lat</div>
            <div class="recommended-information" v-else>Przedstawione oferty dotyczą kwoty kredytu: 25000 zł oraz okresu spłaty {{Number(period.value)*12}} m-cy</div>
            <offerbox v-for="(offer, index) in recommendedOffers" :key="`${index}_offer`" :item="offer" :period="period.value"></offerbox>
        </section>
    </div>
</template>

<script>
import singleQuestion               from '../components/singleQuestion'
import kSlider                      from '../components/kSlider'
import kRadio                       from '../components/kRadio'
import kInput                       from '../components/kInput'
import kDatepicker                  from '../components/calendar/kDatepicker'
import kSelect                      from '../components/kSelect'
import kAgreements                  from '../components/kAgreements'
import navButtons                   from '../components/navButtons'
import offerbox                     from '../components/offerbox'
import kDataWatcher                 from '../components/kDataWatcher';
import information                  from '../components/information';
import creditworthinessResult       from '../components/creditworthiness';
import { mapState, mapActions, mapGetters }   from 'vuex'

export default {
    components: {
        singleQuestion, kSlider, kRadio, kInput, kSelect, navButtons, offerbox,
        kDataWatcher, kDatepicker, creditworthinessResult, 
        kAgreements, information
    },
    computed: {
        ...mapState(['period','loanPercent','otherLoans','cardsLimits',
        'expenses','monthIncome','typeOfEmployment','peopleDependent','endOfContract','companyPeriod',
        'continuityOfEmployment','permaBenefit','creditworthiness',
        'name','phoneNumber','email','personalAgreements','rankomatApi','recommendedOffers','KDataWatcher','calculationNumber']),
        ...mapGetters(['ebrokerApi'])
    },
    created() {
        this.rankomatApi.init({});
    },
    data() {
        return {
            EOCRange: {
                from: 'current',
                to: 'current'
            },
            EOCAge: {
                from: -10,
                to: 0
            },
            EOCOpenDate: new Date()
        }
    },
    methods: {
        ...mapActions([
            'getOffers','sendData','validate_state','countCreditworthiness', 'sendDataRankoAPI'
        ]),
        async generate(cb) {
            let validation = await this.validate_state({group: 'creditworthiness'});

            if (!validation.pageError) {

                this.dataLayer({
                    event: 'ekranW',
                    eventCategory: 'ZdolnoscKredytowa',
                    eventAction: 'ObliczZdolnosc'
                })
                
                await this.sendData();
                const result = await this.getOffers();

                this.countCreditworthiness();

                if (result.status === 'success') {
                    this.$nextTick(()=> {
                        this.scrollToThankYou();
                    })
                }
                
                await this.orderContact();
            }
            cb()
        },
        dataLayer({event,eventCategory,eventAction}) {
            dataLayer.push({event,eventCategory,eventAction})
            console.log('dataLayer',dataLayer);
        },
        async orderContact() {
            let rankoApi = await this.sendDataRankoAPI();
            let ebrokerApiSend = await this.ebrokerApi.sendData();

            // return (rankoApi.success && ebrokerApiSend) 
            return true
        },
        scrollToThankYou() {
            let creditworthinessBox = document.querySelector('.creditworthiness-calculation-wrapper');
            creditworthinessBox.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
    },
}
</script>

<style lang="scss" scoped>
.form-wrapper {
    background-color: #fff;
    box-shadow: 0 0 20px #e1e1e1;
    border: 1px solid #ececec;
    margin: 5px 0 30px;
    display: flex;
    flex-direction: column;
    width: 76%;
    @media screen and (max-width: 900px) {
        width: 100%;
    }

    .title {
        width: 100%;
        padding: 10px 20px;
        margin-bottom: 20px;
        border-bottom: 4px solid #33a574;

        &:not(:first-of-type) {
            margin-top: 30px;
        }
        .text {
            margin-top: 0;
            font-size: 14px;
            font-weight: 600;
            color: #012174;
            margin-bottom: 0;
        }
    }

    .blueBox {
        padding: 15px;
        margin: 10px 0 0;
        background: #ddf3f8;
        color: #21495d;
    }
}

.subtitle {
    display: block;
    font-weight: 600;
    margin-top: 10px;
    color: #012174;
}
.offers {
    padding-bottom: 40px;
}
.recommended-header {
    margin-top: 45px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 500;
    color: #012174;
    margin-bottom: 0;
    text-align: center;
}

.recommended-information {
    font-size: 12px;
    color: #949494;
    display: block;
    font-weight: 400;
    text-align: center;
}

.thank-you-wrapper {
    border-top: 4px solid #6acd44;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #012174;
}
</style>
