import { render, staticRenderFns } from "./modalWrapper.vue?vue&type=template&id=97a2afda&scoped=true&"
import script from "./modalWrapper.vue?vue&type=script&lang=js&"
export * from "./modalWrapper.vue?vue&type=script&lang=js&"
import style0 from "./modalWrapper.vue?vue&type=style&index=0&id=97a2afda&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a2afda",
  null
  
)

export default component.exports