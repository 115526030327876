<template>
    <div class="pic" @click='setActive' :class="{'active': isActive}">
        <div class="pic-name">{{label}}</div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    props: {
        value: [String, Number, Array],
        bgUrl: {
            type: String,
            required: false
        },
        label: {
            type: [Number, String],
            required: true
        },
        setValue: {
            type: [String, Number, Array, Boolean],
            required: true
        },
        setType: {
            type: String,
            default: 'radio'
        },
        permaChecked: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isActive() {
            if (this.setType === 'radio') {
                return this.setValue === this.value
            } else if (this.setType === 'checkbox') {
                return this.value.some(x => x === this.setValue)
            }
        }
    },
    methods: {
        setActive() {
            if (!this.permaChecked) {
                if (this.setType === 'radio') {
                    this.$emit('input', this.setValue)
                } else if (this.setType === 'checkbox') {
    
                    var unique = (this.value.length == 0 || !this.value.includes(this.setValue)),
                        exist = (!unique && this.value.includes(this.setValue)),
                        val = this.value;
                    
                    if (unique) {
                        val.push(this.setValue);
                        
                    }
                    if (exist) {
                        val = this.value.filter(x => x !== this.setValue);
                    }
                    
                    this.$emit('input', val);
                }
            }
        }
    },
    watch: {
        value: {
            handler(value) {
                if (this.permaChecked && (value.length == 0 || !value.includes(this.setValue))) {
                    let arr = (value.length === 0) ? [] : value;
                    arr.push(this.setValue);
                    this.$emit('input', arr);
                }
            },
            immediate: true
        }
    },
}
</script>

<style lang='scss' scoped>

$blue: #20a3dd;

.pic {
    position: relative;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 5px;
    background: #fff;
    border: 1px solid #eeeeee;
    padding: 14px 18px;
    cursor: pointer;
    transition: all .15s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:hover {
        border: 1px solid $blue;
    }

    .pic-name {
        font-size: 13px;
        font-weight: 600;
        color: #333;
        line-height: 12px;
        letter-spacing: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0px 5px;
        text-align: center;
    }

}

.active {
    background: rgba(32, 163, 221, 0.08);
    box-shadow: 0 2px 5px 0 rgba(32, 164, 222, 0.25);
    border-color: #20a3dd;
    background: rgba(32, 163, 221, 0.08) !important;
    position: relative;
    
    .pic-name {
        color: $blue;
    }

}
</style>

