export class RankoApi {
    constructor({productionURL, productID, formCode, productCode, referer = document.referrer, agreementsKey = 'agreements', debug = false}) {
        if (!productionURL) throw 'rankoApi: productionURL is required';
        if (!productID) throw 'rankoApi: productID is required';
        if (!formCode) throw 'rankoApi: formCode is required';
        if (!productCode) throw 'rankoApi: productCode is required';

        this.productionURL = productionURL;
        this.env = this.setEnv();
        this.url = (this.env === 'dev') ? `https://test.rankomat.pl/kalkulator/modules/clientIntegrations/${productID}` : `https://rankomat.pl/kalkulator/modules/clientIntegrations/${productID}`;
        this.formCode = formCode;
        this.productCode = productCode;
        this.referer = referer;
        this.extraParameters = this.getURLParameters();
        this.agreementsKey = agreementsKey;
        this.debug = debug;

        this.clientFormId = null;
        this.securedClientId = null;

        this.schema = {
            phone: {
                dataType: String,
                addMethod: this.keyValue,
                optional: false
            },
            allowancesList: {
                dataType: Array,
                addMethod: this.addAllowance,
                optional: false
            },
            securedClientId: {
                dataType: String,
                addMethod: this.keyValue,
                optional: false
            },
            firstName: {
                dataType: String,
                addMethod: this.keyValue,
                optional: false
            },
            lastName: {
                dataType: String,
                addMethod: this.keyValue,
                optional: false
            },
            email: {
                dataType: String,
                addMethod: this.keyValue,
                optional: true
            },
            auto: {
                prodYear: {
                    dataType: Number,
                    addMethod: this.keyValue,
                    optional: false
                },
                make: {
                    dataType: String,
                    addMethod: this.keyValue,
                    optional: false
                },
                model: {
                    dataType: String,
                    addMethod: this.keyValue,
                    optional: false
                },
                startDate: {
                    dataType: String,
                    addMethod: this.keyValue,
                    optional: false
                }
            },
            answers: {
                dataType: Array,
                addMethod: this.addAnswer,
                optional: false
            },
            // contactDate: {
            //     dataType: Date,
            //     addMethod: this.addDate
            // }
        }

        this.dataToSend = {};
    }

    init({addURLParameters = false}) {
        return new Promise((resolve, reject) => {
            let data = {
                formCode: this.formCode, 
                productCode: this.productCode, 
                referer: this.referer,
                originHttpUrl: window.location.href
            };

            if (Object.keys(this.extraParameters).length !== 0 && addURLParameters) {
                data['answers'] = {
                    answers: []
                };

                for (var param_key in this.extraParameters) {

                    data.answers.answers.push({
                        question: param_key,
                        answer: this.extraParameters[param_key]
                    })
                }

                if (this.extraParameters.hasOwnProperty('rt_so') && this.extraParameters.hasOwnProperty('rt_med')) {
                    data.answers.answers.push({
                        question: 'rt_somed',
                        answer: this.extraParameters['rt_so'] + ' / ' + this.extraParameters['rt_med']
                    })
                }
                // this.removeSearchParameters()
            }


            let cfg = {
                method: 'POST',
                headers: {'Content-Type' : 'text/plain; charset=UTF-8'},
                credentials: 'include',
                body: JSON.stringify(data)
            }

            fetch(`${this.url}/init`, cfg)
            .then(req => req.json())
            .then(data => {
                this.clientFormId = data.clientFormId;
                this.securedClientId = data.securedClientId;

                this.dataToSend = {
                    securedClientId: data.securedClientId,
                    clientFormId: data.clientFormId
                }

                if (this.debug) console.log('RankoAPI init: success', this.dataToSend)
                resolve({status: 'success', data})
            })
            .catch(err =>  {
                if (this.debug) console.log('RankoAPI init: failed')
                reject({status: 'failed', error: err})
            })
        })
    }

    getURLParameters() {
        let urlParams = window.location.search.substr(1);
        let prefixes = ['utm_', 'rt_', 'gclid','fbclid'];
        let URLparams = {}

        for (var prefix of prefixes) {
            if (urlParams.indexOf(prefix) != -1) {
                var pattern = prefix+".*?(&|$)";
                var regex = new RegExp(pattern,'g');
                var params = urlParams.match(regex);
                for (var param of params) {
                    param = (param.indexOf('&') != -1) ? param.replace('&','') : param;
                    var [key, val] = param.split('=');
                    URLparams[encodeURI(key)] = encodeURI(val);
                }
            }
        }

        return URLparams
    }

    removeSearchParameters() {
        var removedSearch = window.location.href;
        removedSearch = (removedSearch.indexOf('?') != -1) ? removedSearch.substring(0,removedSearch.indexOf('?')) : removedSearch;
        window.history.replaceState({}, document.title, removedSearch);
    }

    setEnv() {
        return (window.location.origin.indexOf(this.productionURL) !== -1) ? 'production' : 'dev'
    }

    keyValue({item, dataType, key, value = null}) {
        let setValue = (item) ? item.value : value;
        this.createNestedObject(this.dataToSend,key,dataType(setValue));
    }

    createNestedObject(obj, keyPath, value) {
        let lastKeyIndex = keyPath.length-1;
        for (var i = 0; i < lastKeyIndex; ++ i) {
          let key = keyPath[i];
          if (!(key in obj)){
            obj[key] = {}
          }
          obj = obj[key];
        }
        obj[keyPath[lastKeyIndex]] = value;
     }

    addAllowance({item, text = null, type = '', key = 'allowancesList'}) {
        if (!item && !text) throw 'rankoApi.addAllowance: text is required';

        this.dataToSend[key] = (!this.dataToSend.hasOwnProperty(key) || this.dataToSend[key].length === 0) ? [] : this.dataToSend[key];
        let setText = text,
            setType = type;

        if (item && Array.isArray(item)) {
            for (let agreement of item) {
                if (agreement.value) {
                    setText = agreement.text;
                    setType = (agreement.type) ? agreement.type : type;
                    this.dataToSend[key].push({text: String(setText), type: String(setType)});
                }
            }
        } else if (text) {
            this.dataToSend[key].push({text: String(setText), type: String(setType)});
        } else {
            throw `addAllowances: there is no item and no text/type variables`
        }
    }

    addAnswer({item, dataType, question = null, answer = null, personalData = false, key}) {
        if (!key) throw `rankoApi.addAnswer: key is required`;
        if (!item && !question) throw 'rankoApi.addAnswer: question is required';
        if (!item && !answer) throw 'rankoApi.addAnswer: answer is required';

        let currentArray = (!this.dataToSend.hasOwnProperty(key) || this.dataToSend[key].length === 0) ? [] : this.dataToSend[key];
        let setQuestion = (item) ? item.question : question;
        let setAnswer = (item) ? item.value : answer;
        let setPersonalData = (item) ? item.personalData : personalData;

        this.dataToSend[key] = dataType(...currentArray, {question: String(setQuestion), answer: String(setAnswer), personalData: Boolean(setPersonalData)});
    }

    // addDate() {
    //     formatDate: (value) => {
    //         const day = value.getDate();
    //         const month = value.getMonth() + 1;
    //         const year = value.getFullYear();
    //         const hours = value.getHours();
    //         const minutes = value.getMinutes();
    //         const seconds = value.getSeconds();
    //         const milliseconds = value.getMilliseconds();

    //         return (
    //           String(day).padStart(2, '0') +
    //           '-' +
    //           String(month).padStart(2, '0') +
    //           '-' +
    //           year +
    //           ' ' +
    //           String(hours).padStart(2, '0') +
    //           ':' +
    //           String(minutes).padStart(2, '0') +
    //           ':' +
    //           String(seconds).padStart(2, '0') +
    //           '.' +
    //           String(milliseconds).padStart(3, '0')
    //         );
    //       },
    //     this.dataToSend['contactDate']
    // }

    prepereData({state}) {
        return new Promise((resolve, reject) => {
            try {
                if (!state) reject({status: 'state failed'});

                for (let key in state) {
                    let item = state[key];
                    if (item.hasOwnProperty('rankomatApiKey')) {this.addDataGenerator({item})}
                    if (key === this.agreementsKey) this.addAllowance({item});
                }
                if (this.debug) console.log('prepereData', this.dataToSend);
                resolve({status:'success'})
            }
            catch {
                reject({status: 'prepereData failed'})
            }
        })
    }

    addDataGenerator({item}) {
        let arrayOfKeys = item.rankomatApiKey;
        let root = this.schema;

        function addBySchema({itemSchema}) {

            if (!itemSchema.optional || (itemSchema.optional && item.value !== '')) {
                itemSchema.addMethod.bind(this)({item, dataType: itemSchema.dataType, key: arrayOfKeys})
            }

        }

        for (let key of arrayOfKeys) {
            if (!root.hasOwnProperty(key)) throw `RankomatApi - Schema doesn't have key ${key}`;
            if (root[key].hasOwnProperty('dataType') && root[key].hasOwnProperty('addMethod')) {
                addBySchema.bind(this)({itemSchema: root[key]});
            } else {
                root = root[key];
            }
        }

    }

    send() {
        return new Promise((resolve, reject) => {
            let cfg = {
                method: 'POST',
                headers: {'Content-Type' : 'text/plain; charset=UTF-8'},
                credentials: 'include',
                body: JSON.stringify(this.dataToSend)
            }

            fetch(`${this.url}/answers`, cfg)
            .then(req => {
                if (req.status === 200) {
                    resolve({status: 'success'})
                } else {
                    reject({status: 'failed', msg: 'something went wrong'});
                }
            })
            .catch(err => reject({status: 'failed', error: err}))
        })
    }

}