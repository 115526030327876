export class KValidate {
    constructor({item = null, state = null, debug = false, scrollToError = false, validationGroup = null, agreementsKey = null}) {
        return new Promise((resolve,reject) => {
            this.item = item;
            this.state = state;
            this.errors = null;
            this.validationGroup = validationGroup;
            this.agreementsKey = agreementsKey;
    
            this.filters = this.regexValidatorsCollection();
    
            this.log = {
                error: (msg) => (debug) && console.error(msg),
                info: (msg) => (debug) && console.log(`%c${msg}`, 'background: green; color: white')
            }
            try {
                if (this.item) {this.itemValidation({item: this.item, cb: () => {
                    resolve({isValid: !item.error})
                }})}
                else {
                    this.stateValidation({state: this.state, cb: () => {
                        this.errors = this.checkErrors({state: this.state});
        
                        if (this.errors.firstErrorID && scrollToError) {
                            this.scrollTo({id: this.errors.firstErrorID});
                        }
            
                        if (!this.errors.pageError) {
                            this.resetUnregistered({state, debug})
                        }
                        resolve(this.errors)
                    }})
                    
                }
            }
            catch {
                reject('failed')
            }
        })
    }

    scrollTo({id}) {
        let firstError = document.querySelector('#'+id);
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: firstError.getBoundingClientRect().top + window.scrollY - 90
        });
    }

    regexValidatorsCollection() {
        return {
            isFill: (val) => (val == "" || val === 0 || val.length == 0 || val == null || val === undefined),
            alphabet: (val) => /[0-9:/!@#\$%\^\&*\)\(|"?'+=,<>;._\\]+/g.test(val),
            numbers: (val) => /[a-zA-Z:/!@#\$%\^\&*\)\(|"?'+=,<>;._\\]+/g.test(val),
            apiHLR: (val) => this.phoneHLRValidation({number: val}),
            apiEmail: (val) => this.mailApiValidation({email: val}),
            afterDecimal: (val) => {let test = /^[0-9]{1,2}([,|.][0-9]{1,2})?$/g.test(val); return !test}
        }
    }

    isValidable({item}) {
        let isEmptyArray = (Array.isArray(item.value) && item.value.length === 0);
        let isBool = typeof item.value === 'boolean';
        let isEmptyValue = item.value === '' || item.value === null || item.value === undefined;

        if (!item.required && (isEmptyValue || isBool || isEmptyArray)) {
          item.error = false;
          return false;
        }
        return true;
    }

    setError({item, method}) {
        item.errorMethod = method;
        item.error = true;
        return false;
    }

    async validate({item}) {
        if (this.isValidable({item})) {
            let regexValidation = this.regexValidatorsCollection();
            let itemExtraValidMethod = item.validMethod || [];
            let validationMethods = ['isFill',...itemExtraValidMethod];
            this.resetError({item});


            //Regex Validation
            for (let method of validationMethods) {
                if (!regexValidation.hasOwnProperty(method)) {
                    this.log.error(`${item.name}: There is no ${method} in regexValidatorsCollection @KValidate`)
                    return false
                }

                if (method.indexOf('api') > -1) {
                    let result = await regexValidation[method](item.value);
                    if (!result) return this.setError({item,method})
                } else {
                    if (regexValidation[method](item.value)) {
                        return this.setError({item,method})
                    }  
                }
            }

        } else {
            this.log.error(`${item.name} Item error or is not validable`)
        }
    }

    resetError({item}) {
        item.error = false;
    }

    async singleValidation({item}) {
        await this.validate({item});
    }

    showErrors() {
        console.log('this.errors',this.errors);
    }

    itemValidation({item,cb}) {
        this.singleValidation({item});
        cb();
    }

    stateValidation({state, cb}) {
        if (state) {
            var promises = [];
            for (let question in state) {
                let item = state[question];
                if (item !== null) {
                    let validable = this.checkGroup({item})
                    if (validable && item.hasOwnProperty('required') && item.required && item.register) {
                        promises.push(this.singleValidation({item}))
                    }
                    else if (this.agreementsKey !== null && question === this.agreementsKey) {
                        promises.push(this.validateAgreements({item}));
                    }
                }
            }
            Promise.all(promises)
            .then(res => {
                cb()
            })
        } else {
            this.log.error('kValidate: state is empty');
        }
    }

    validateAgreements({item}) {
        for (let agreement of item) {
            if (agreement.required) this.validate({item: agreement});
        }
    }

    async phoneHLRValidation({number}) {
        if (number) {
            number = (number.indexOf('-') > -1) ? String(number).replace(/-/g,'') : number
            var apiUrl = 'https://api.rankolabs.net/kalkulator/validPhone/' + number;
            let req = await fetch(apiUrl)
            let response = await req.json()
            return response.isValid 

        }
    }

    mailApiValidation({email}) {
        if (email) {
            var apiUrl = 'https://api.rankolabs.net/kalkulator/validEmail/' + email;
            return fetch(apiUrl)
                .then(response => response.json())
                .then(response => {
                        return response.isValid
                    })
                    .catch(error => {
                        return error
                    })
        }
    }

    checkGroup({item}) {
        return (item !== null && this.validationGroup !== null && item.hasOwnProperty('validationGroup') && this.validationGroup === item.validationGroup) || (this.validationGroup === null)
    }

    checkErrors({state}) {
        let agreementsError = false, 
            questionsError, 
            stateArray = Object.values(state);

        questionsError = stateArray.find((item) => {
            let validable = this.checkGroup({item}) 
            if (item) return (validable && item.required && item.register && item.error)
            return false
        })
        if (this.agreementsKey !== null && state.hasOwnProperty(this.agreementsKey)) agreementsError = state[this.agreementsKey].find((agreement) => (agreement.required && agreement.error))

        function firstErrorID() {
            if (Boolean(questionsError)) return questionsError.name
            else if (!questionsError && agreementsError) return this.agreementsKey
            else return false
        }

        return {
            firstErrorID: firstErrorID.bind(this)(),
            pageError: Boolean(firstErrorID.bind(this)()), 
            questionsError: Boolean(questionsError),
            agreementsError: Boolean(agreementsError)
        }
    }

    resetUnregistered({state, debug}) {
        for (var data in state) {
            var item = state[data];
            if (item !== null) {
                if (item.hasOwnProperty('register') && !item.register) {
                  item.value = JSON.parse(JSON.stringify(item.resetValue));
                  item.error = false;
                  if (debug) console.log(`%c${data} value has been reset`, 'background-color: red; color: white', item.value);
                }
            }
        }
    }
}