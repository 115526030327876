<template>
    <div class="day" :class="activeClass()" @click="setDay(day)" >{{day}}</div>
</template>

<script>
export default {
    props: {
        selected: {type: Object},
        save: {type: Object},
        day: {type: Number},
        selectedDay: {type: Number},
        disabled: {type:Boolean, default:false},
        rangeStart: {
            type: Object, 
            require: true
        },
        rangeEnd: {type: Object, require: true}
    },
    computed: {
        isSelected() {
            return (this.selectedDay === this.day && this.compare);
        },
        tooEarly() {
            if (this.rangeStart) {
                if (this.rangeStart.year === this.selected.year && 
                    this.rangeStart.month === this.selected.month) {
                        return (this.rangeStart.day > this.day)
                } else if (this.rangeStart.year === this.selected.year && 
                    this.rangeStart.month > this.selected.month) {
                        return true
                }
                return false
            }
            return false
        },
        tooLate() {
            if (this.rangeStart) {
                if (this.rangeEnd.year === this.selected.year && 
                    this.rangeEnd.month === this.selected.month) {
                        return (this.rangeEnd.day < this.day)
                } else if (this.rangeEnd.year === this.selected.year && 
                    this.rangeEnd.month < this.selected.month) {
                        return true
                }
                return false
            }
            return false
        },
        compare() {
            return (this.selected.month === this.save.month && this.selected.year === this.save.year)
        }
    },
    methods: {
        setDay(value) {
            if (!this.tooEarly && !this.tooLate) {
                this.$emit('setDay',value);
                this.$emit('close');
            }
        },
        activeClass() {
            return {
                'selected': this.isSelected,
                'disabled': this.disabled,
                'date-early': this.tooEarly,
                'date-late': this.tooLate
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$lightblue: #21A3DD;
$bRadius: 3px;

.day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 32px;
    height: 32px;
    border: 1px solid transparent;
    font-size: 13px;
    color: #555555;
    // transition: all linear .1s;
    &:hover {
        border-radius: 20px;
        background: #ebecf4;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
    }

}
.selected {
    border-radius: 20px;
    background: #ebecf4;
    cursor: inherit;
    &:hover {
        // background: #20a3dd;
        // text-decoration: underline;
        // cursor: inherit;
    }
}

.disabled {
    color: transparent;
        &:hover {
        background: white;
        cursor: default;
    }
}
.date-early, .date-late {
    opacity: .4;
    text-decoration: line-through;
    &:hover {
        text-decoration: line-through;
        background: white;
        cursor: default;
    }
}
</style>


