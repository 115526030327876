<template>
    <div class="question-box" :id="question.name" :style="{padding}" :class="[{'question-text-top': qTextTop},{'question-text-center': qTextCenter}]">
        <div class="question-text" :class="{'empty': question === null}" :style="{'margin': qCustomMargin}">
            <span v-if="question != null && noName == false">{{setQuestion || question.question}}</span>
            <div v-if="!question.required && !hideNoRequiredMsg" class="blue-msg">Pytanie nieobowiązkowe</div>
            <div class="question-extra-text" v-if="additionalText">{{additionalText}}</div>
        </div>
        <div class="answer-wrapper">
            <div class="question-answer" :style="{maxWidth: answerWidth}">
                <slot name="answer"></slot>
            </div> 
            <div class="error-box" v-if="displayError && showError && (errorMethod == 'isFill' || errorMethod == 'arrayIsFill')">
                <slot name="errorMessage">{{customError}}</slot>
            </div>
            <div class="error-box" v-if="displayError && showError && errorMethod == 'lessThan'">
                <slot name="errorMessage">{{lessMsg}}</slot>
            </div>
            <div class="error-box" v-if="displayError && showError && (errorMethod == 'moreThan30' || errorMethod == 'moreThan50')">
                <slot name="errorMessage">{{moreThanMsg}}</slot>
            </div>
            <div class="error-box" v-if="displayError && showError && errorMethod == 'phone_number'">
                <slot name="errorMessage">Wpisany przez Ciebie numer jest nieprawidłowy. Numer telefonu powinien składać się z 9 cyfr</slot>
            </div>
            <div class="error-box" v-if="displayError && showError && errorMethod == 'HLR'">
                <slot name="errorMessage">Wpisany przez Ciebie numer jest nieprawidłowy</slot>
            </div>
            <div class="error-incorrect" v-if="displayError && showError && errorElse">
                <slot name="errorMessage">Nieprawidłowo wypełnione pole</slot>
            </div>
            <div class="error-incorrect" v-if="displayError && showError && (errorMethod == 'email' || errorMethod=='api_email')">
                <slot name="errorMessage">Podany adres email jest nieprawidłowy</slot>
            </div>
            <slot name="information"></slot>
        </div>
        <div v-if="tip" class="question-tip">
            <div @click="toggleTip" class="icon" :style="{'top': countMarginTop}">
                <svg id="help-line-round" viewBox="0 0 24 24">
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path>
                </svg>
            </div>
            <transition name="fadeleft">
                <div v-if="showTip" class="tip-box">
                    <div class="tip-box-content">
                        <span @click="showTip = false" class="tip-box-close">×</span>
                        <h3>{{tipTitle}}</h3>
                        <div class="content"><slot name="tip"></slot></div>
                    </div>
                    <div class="tip-footer">
                        <h3 class="text">
                            Problem z wypełnieniem formularza?
                        </h3>
                        <div class="phone-wrapper">
                            <div @click="call('717367555')" class="fa fa-phone icon-blue-circle"></div>
                            <div class="phone-number">71 736 75 55</div>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="tip-modal-wrapper">
                <modalWrapper v-if="showTip" @close="showTip = false" :maxWidth="'95vw'">
                    <template slot="title">{{tipTitle}}</template>
                    <template slot="content"><div class="modalTipBody"><slot name="tip"></slot></div></template>
                </modalWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'
import modalWrapper from './modalWrapper.vue'

export default {
    components: {
        modalWrapper
    },
    mounted() {
        this.question.register = true;
    },
    destroyed() {
        this.question.register = false;
    },
    data() {
        return {
            showTip: false,
            modalTip: false
        }
    },
    computed: {
        countMarginTop() {
            let [top] = this.padding.split(' ');
            return top
        },
        qValue: {
            get() {
                return this.question.value
            },
            set(value) {
                this.question.value = value
            }
        },
        showError() {
            let errorStatus = this.question.error;
            return errorStatus;
        },
        errorMethod() {
            if (this.question.error) return this.question.errorMethod
        },
        errorElse() {
            var otherThan = ['isFill','arrayIsFill','lessThan','moreThan30','moreThan50','phone_number','HLR','email','api_email'];
            return !otherThan.some((methodName) => {
                return methodName == this.errorMethod
            })
        }
    },
    props: {
        tip: {
            type: Boolean,
            default: false
        },
        tipTitle: {
            type: String,
            default: ''
        },
        question: {
            type: Object,
            default: null
        },
        displayError: {
            type: Boolean,
            default: true,
            required: false
        },
        lessMsg: {
            type: String,
            default: '',
            required: false
        },
        moreThanMsg: {
            type: String,
            default: ''
        },
        padding: {
            type: String,
            default: '7px 10px'
        },
        qTextTop: {
            type: Boolean,
            default: false
        },
        qTextCenter: {
            type: Boolean,
            default: false
        },
        setQuestion: {
            type: String,
            default: ''
        },
        noName: {
            type: Boolean,
            default: false
        },
        answerWidth: {
            type: String,
            default: ''
        },
        customError: {
            type: String,
            default: 'Odpowiedź jest wymagana'
        },
        liveValidation: {
            type: Boolean,
            default: true
        },
        hideNoRequiredMsg: {
            type: Boolean,
            default: false
        },
        qCustomMargin: {
            type: String,
            default: ''
        },
        additionalText: {
            type: String,
            default: ''
        }
    },
    methods: {
        ...mapActions(['validate']),
        closeTips() {
            let childrenArray = this.$parent.$children;  
            childrenArray.forEach(element => {
                if (element.hasOwnProperty('showTip')) element.showTip = false;
            });
        },
        call(number) {
            window.location.href="tel:"+number;
        },
        addToDataLayer() {
            var dl = {
                event: 'XXXXXXXX',
                eventCategory: 'XXXXXXXXXXX',
                eventAction:  'otwarcie infotipa ',
                eventLabel:  `XXXXXXXX`
            }
        },
        toggleTip() {
            if (!this.showTip) this.addToDataLayer();
            this.closeTips();
            this.showTip = !this.showTip;
        }
    },
     watch: {
        qValue(value) {
            if (this.question.register && this.liveValidation) {
                this.validate({item: this.question});
            }
        }
    },
}
</script>

<style lang='scss' scoped>
$xlarge: 1800px;
$large: 1200px;
$medium: 1024px;
$small: 640px;
$xsmall: 420px;

$blue: #20a3dd;

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}

.question-box {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0px;
    font-size: 13px;
    flex-wrap: wrap;
    @media screen and (max-width: $small) {
        margin-bottom: 6px;
    }

    &.question-text-top {
        .question-text {
            margin-top: 0px;
        }
    }

    &.question-text-center {
        .question-text {
            align-self: center;
        }
    }

    .question-text {
        flex: 0 0 40%;
        padding: 0 15px 0 70px;
        text-align: right;
        font-weight: 600;
        order: 1;
        // margin-top: 5px;
        @media screen and (max-width: $small){
            flex: 0 0 80%;
            text-align: left;
            margin-bottom: 10px;
            margin-top: 0px;
        }
        @media screen and (max-width: $xsmall) {
            padding: 0 10px;
        }

        .blue-msg {
            font-size: 11px;
            color: #20a3dd;
            display: block;
            font-weight: 400;
        }
    }
    .question-extra-text {
        font-size: 11px;
        color: #949494;
        display: block;
        font-weight: 400;
        line-height: 2;
    }
    .answer-wrapper {
        order: 2;
        display: flex;
        flex: 0 0 55%;
        flex-direction: column;
        padding: 0 25px;
        @media screen and (max-width: $small) {
            flex: 0 0 100%;
        }
        @media screen and (max-width: $xsmall) {
            padding: 0 10px;
        }

        .question-answer {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 100%;
            position: relative;
           
            label {
                display: flex;
                align-items: flex-start;
                margin-top: 4px;
                margin-bottom: 4px;
                @media screen and (max-width: $xsmall) {
                    width: 100%;
                }
                .checkbox-text {
                    width: 100%;
                    font-weight: 600;
                    @media screen and (max-width: $xsmall) {
                        width: 55vw;
                    }
                }
                input {
                    margin-top:3px;
                }
            }
            input {
                &::placeholder {    
                    color: #b5b5b5;
                }
            }
        }

        .error-box {
            color: #4d3f24;
            background-color: #f8ecc0;
            // border-color: #ffeeaa;
            margin-bottom: 14px;
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 8px 10px;
            font-size: 13px;
            display: block;
            float: left;
            width: 300px;
            @media screen and (max-width: $xsmall) {
                width: 100%;
            }
        }

        .error-incorrect {
            color: #582c2b;
            background-color: #e9c3c3;
            margin-bottom: 14px;
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 8px 10px;
            font-size: 13px;
            display: block;
            float: left;
            width: 300px;
            @media screen and (max-width: $xsmall) {
                width: 100%;
            }
        }
    }
    
    .question-tip {
        flex: 0 0 10%;
        order: 3;
        align-self: flex-start;
        @media screen and (max-width: $small) {
            order: 1;
        }

        .tip-modal-wrapper {
            @media screen and (min-width: 900px) {
                display: none;
            }
        }

        .icon {
            cursor: pointer;
            
            width: 24px;
            height: 24px;
            position: absolute;
            right: 30px;
            line-height: 25px;
            z-index: 5;
            svg {
                fill: #b1b1b1;
                transition: all 0.1s linear;
                &:hover {
                    fill: $blue;
                }
            }
        }
        .tip-box {
            transition: all 0.2s ease-in-out 0s;
            position: absolute;
            top: -30px;
            // right: -350px;
            right: -300px;
            margin-left: 30px;
            z-index: 9;
            box-shadow: 0 0 15px -7px rgba(0, 0, 0, 0.4);
            @media screen and (max-width: 900px) {
                display: none;
            }
            // &:after {
            //     position: absolute;
            //     width: 0;
            //     height: 0;
            //     content: '';
            //     z-index: 2;
            //     border-right: 0.75em solid #fff;
            //     border-bottom: 0.5em solid transparent;
            //     border-top: 0.5em solid transparent;
            //     top: 37px;
            //     left: -9px;
            //     bottom: 100%;
            // }

            @media screen and (max-width: $large) {
                right: -240px;
            }

            .tip-box-content {
                position: relative;
                background: #fff;
                padding: 20px 25px 45px 25px;
                width: 286px;
                color: #3c3c3c;
                overflow: auto;
                @media screen and (max-width: $large) {
                    padding: 20px 15px 45px;
                    width: 200px;
                }


                .tip-box-close {
                    font-size: 33px;
                    line-height: 1em;
                    margin-top: 0px;
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    cursor: pointer;
                }

                .content {
                    width: 90%;
                    margin: 10px 0px;
                    font-size: 13px;
                    color: #868686;
                    letter-spacing: 0;
                    font-weight: 400;
                }

                h3 {
                    color: #012174;
                    margin-bottom: 15px;
                    margin-right: 20px;
                    margin-top: 0px;
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 600;
                }

            }

            .tip-footer {
                background: #F3F3F3;
                text-align: center;
                padding: 60px 25px 20px 25px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &::after {
                    content: '';
                        position: absolute;
                        background: url('https://rankomat.pl/theme/rankomat/images/top-kontakt-retina.png') no-repeat;
                        width: 90px;
                        top: -40px;
                        z-index: 999999;
                        height: 82px;
                        left: 0;
                        right: 0;
                        margin: auto;
                }

                h3.text {
                    color: #012174;
                    margin-bottom: 0;
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 600;
                    max-width: 180px;
                }

                .phone-wrapper {
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icon-blue-circle {
                        padding: 10px 13px !important;
                        width: 35px;
                        height: 35px;
                        line-height: 1;
                        background: #20a3dd;
                        border-radius: 50%;
                        background-clip: padding-box;
                        display: inline-block;
                        vertical-align: top;
                        color: white;
                        font-size: 15px;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .phone-number {
                        margin-left: 15px;
                        line-height: 1.3;
                        font-size: 16px;
                        font-weight: bold;
                        color: $blue;
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .modalTipBody {
            padding: 20px;
        }

        .fadeleft-enter-active, .fade-leave-active {
            transition: all .2s;
        }
        .fadeleft-enter /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
        .fadeleft-leave-to {
            opacity: 0;
        }
    }
    .empty {
        margin-bottom: 0px;
    }
}
</style>


