<template>
    <div class="block-wrapper">
        <div class="title">{{title}}</div>
        <div class="list">
            <div class="item" v-for="(item,index) in list" :key="index+'_item'">
                <div class="description">{{item.desc}}</div>
                <template v-if="item.cash">
                    <div class="value">{{item.value}} zł</div>
                </template>
                <template v-if="!item.cash">
                    <div class="value" v-if="item.value === true">Tak</div>
                    <div class="value" v-else-if="item.value === false">Nie</div>
                    <div class="value" v-else>{{item.value}}</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: []
        }
    }
}
</script>


<style lang="scss" scoped>
.block-wrapper {
    margin-bottom: 25px;
    .title {
        text-align: left;
        color: #012174;
        font-weight: 600;
    }
    .list {
        margin-left: 5px;
        font-size: 13px;
        .item {
            display: flex;
            margin-top: 8px;

            .description {
                flex: 0 0 50%;
                text-align: left;
                color: #555555;
            }
            .value {
                flex: 0 0 50%;
                text-align: left;
                margin-left: 5px;
                color: #0a0a0a;
                font-weight: 600;
            }
        }
        
    }
}
</style>

