<template>
    <div class="agreement-wrapper">
        <div class="body" v-if="agreement.split">
            <input :id='agreement.name' class="multibox" type="checkbox" :value="'checked'"  v-model="agreement_value">
                <div v-if="agreement.short" class="text">
                    <label :for='agreement.name'><span class="agreement-text">{{agreement.text | short(15)}}</span></label>
                    <span @click="agreement.short = false" class="read-more">
                        <span class="text">Pokaż pełną treść zgody</span>
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </span>
                </div>
            <div v-else class="text">
                <label :for='agreement.name'><span class="agreement-text">{{agreement.text}}</span></label>
                <span @click="agreement.short = true" class="read-more">
                    <span class="text">Ukryj</span>
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                </span>
            </div>
        </div>
        <div class="body" v-else>
            <input :id='agreement.name' class="multibox" type="checkbox" :value="'checked'"  v-model="agreement_value">
            <div class="text">
                <label :for='agreement.name'><span class="agreement-text">{{agreement.text}}</span></label>
            </div>
        </div>

        <div class="error-box" v-if="singleErrors && agreement.error">
                <slot name="singleErrorMsg">Zgoda jest wymagana</slot>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';

export default {
    props: {
        agreement: {
            type: Object
        },
        singleErrors: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        agreement_value: {
            get() {return this.agreement.value},
            set(value) {this.agreement.value = value}
        },
    },
    methods: {
        ...mapActions([
            'validate'
        ]),
        toggleAll(value) {
            this.$parent.$children.forEach(agreement => {
                if (agreement.agreement.name !== 'acceptAll') {
                    agreement.agreement.value = value;
                }
            });
        }
    },
    watch: {
        agreement_value(value){
            if (this.agreement.name === 'acceptAll' && value) this.toggleAll(value)
            else this.validate({item: this.agreement});
        }
    },
    filters: {
        short(val, cutLength) {
            var arr = val.split(' ');
            if (arr.length > cutLength) {
                arr.length = cutLength;
                var short_string = arr.join(' ').trim();
                return short_string + "...";
            }
            return val;
        }
    }
}
</script>

<style lang="scss" scoped>

$blue: #20a3dd;
.agreement-wrapper {
   
    .body {
        padding: 5px 5px;
        // margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        .text {
            line-height: 16px;
            font-weight: 600;
            text-align: left;
            label {
                display: inline;
            }
            .read-more {
            font-size: 11px;
                .text {
                    color: $blue;
                    text-decoration: underline;
                    cursor:pointer;
                }
                i {
                    font-size: 10px;
                    color: $blue;
                }
                
                &:hover {
                    opacity: .7;
                }
            }
        }

        input[type='checkbox'] {
            appearance: none;
            background: white;
            margin: 0px;
            border: 1px solid #d5d5d5;
            width: 18px;
            height: 18px;
            vertical-align: bottom;
            &:focus {
                box-shadow: 0 0 0px 3px rgba(32, 163, 221, 0.4);
                outline: none;
            }
            &:hover {
                border: 1px solid $blue;
            }
            &:checked {
                &:before {
                    content: '';
                    font-family: 'Font Awesome 5 Free' !important;
                    font-weight: 900;
                    border: 1px solid $blue;
                    width: 18px;
                    height: 18px;
                    position: relative;
                    background-color: $blue;
                    display: block;
                    top: -1px;
                    left: -1px;
                    text-align: center;
                    font-size: 11px;
                    color: white;
                }
            }
        }

        input[type='checkbox'] {
            border-radius: 3px;
            margin-top: 2px;
            margin-right: 15px;
            flex: 0 0 18px;
            cursor: pointer;
            &:checked {
                &:before {
                    content: '\f00c';
                    width: 16px;
                    height: 16px;
                    border-radius: 3px;
                    line-height: 1.5;
                    font-size: 11px;
                }
            }
        }

        .agreement-text {
            font-size: 12px;
            cursor: pointer;
            flex: 1;
            font-weight: 600;
        }
    }

    .error-box {
        color: #4d3f24;
        background-color: #f8ecc0;
        // border-color: #ffeeaa;
        margin-bottom: 14px;
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 8px 10px;
        font-size: 13px;
        display: block;
        float: left;
        width: 100%
    }

}
</style>


