<template>
<transition name="mod">
    <div class="mod-mask">
        <div class="mod-wrapper">
            <div class="mod-container" :style="{maxWidth}">
                <div @click="close()" class="close">×</div>
                <div class="mod-header">
                    <div class="mod-title">
                        <h3>{{title}}</h3>
                    </div>
                </div>
                <slot name="content"></slot>
                <div class="mod-footer">
                    <button @click="close()" type="button" class="button">Zamknij</button>
                    <button @click="secBtnFN()" v-if="secBtn && !secBtnHideIf" type="button" class="button-green" :disabled="inactive"><i v-if="spinner" class="fa fa-circle-o-notch fa-spin fa-fw"></i> {{secBtnText}}</button>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    mounted() {
        // ga4i.addVirtual();
    },
    props: {
        maxWidth: {
            type: String,
            default: '990px'
        },
        secBtn: {
            type: Boolean,
            default: false
        },
        secBtnText: {
            type: String,
            default: 'Dalej'
        },
        secBtnFN: {
            type: Function,
            // default: function(){console.log('Beep')}
        },
        secBtnHideIf: {
            type: Boolean,
            default: false
        },
        spinner: {
            type: Boolean,
            default: false
        },
        inactive: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }

    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>


<style lang="scss" scoped>

@mixin button-style($bg-color, $font-color, $shadow-color, $font-size, $padding, $max-width) {
    &:focus {
        outline: none;
    }
    display: inline-block;
    max-width: $max-width;
    width: 100%;
    margin: 0 5px;
    font-size: $font-size;
    padding: $padding;
    background: $bg-color;
    color: $font-color;
    box-shadow: 0 2px 0 0 $shadow-color;
    border: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Montserrat", Helvetica Neue, Arial, sans-serif;
    line-height: 1;
    cursor: pointer;
    border-radius: 30px;
    transition: all .1s linear;
    &:hover {
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, .05);
    }
}

$blue: #20a3dd;

.mod-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity .3s ease;
  cursor: default;

    .mod-wrapper {
    display: table-cell;
    vertical-align: middle;
    
        .mod-container {
        margin: 0px auto;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
        transition: all .3s ease;
        position: relative;
        max-height: 100vh;
        overflow-y: auto;

        @media screen and (max-width: 600px) {
            width: 90vw;
        }

            .close {
                position: absolute;
                right: 10px;
                top: 5px;
                font-size: 28px;
                font-weight: 400;
                color: $blue;
                transition: all 0.1s linear;
                opacity: 1;
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }
            }

            .mod-header {
                width: 100%;
                text-align: left;
                padding: 10px 38px 10px 20px;
                background-color: white;
                border-bottom: 1px solid #f3f3f3;

                .mod-title {
                    font-size: 16px;
                    color: #012174;
                    font-weight: 600;
                    margin-bottom: 0;
                    letter-spacing: 0;
                }
            }
            .mod-footer {
                border-top: 1px solid #f3f3f3;
                background: #F3F4F2;
                padding: 10px 38px 10px 20px;
                display: flex;
                justify-content: center;
               
                .button {
                    @include button-style(#fff, #012174, rgba(0,0,0,0.2), 14px, 15px 10px, 200px);
                }
                .button-green {
                    @include button-style(#6acd44,#fff,#52AF2F, 14px, 15px 10px, 200px);
                    &:disabled {
                        opacity: .7;
                    }
                }
                .filter {
                    @include button-style(#6acd44, white, #52af2f, 14px, 15px 10px, 200px);
                }

                @media screen and (max-width: 400px) {
                    flex-direction: column-reverse;
                    align-items: center;
                    .button {
                        margin: 10px;
                    }
                }
            }
        }
    }   
}

.mod-enter {
  opacity: 0;
}

.mod-leave-active {
  opacity: 0;
}
</style>

