<template>
    <div class="mod-body">
        <div class="header">
            <div class="logo-wrapper">
                <img v-if="item.nazwa_banku.logo" :src="`${item.nazwa_banku.logo.data.full_url}`" alt="">
            </div>
            <div class="price-wrapper">
                <div class="price">{{item.installment | beautifyNumbers}} <span class="smaller">zł</span></div> <div class="description">Miesięczna rata</div>
            </div>
            <div class="cta-wrapper">
                <a :href="parse_link" target="_blank">
                    <button type="button" class="button dark-blue large setwidth">Wybierz</button>
                </a>
                <!-- <div class="mail"><i class="fa fa-envelope" aria-hidden="true"></i> Wyślij ofertę na e-mail</div> -->
            </div>
        </div>
        <div class="body">
            <div class="details-full">
                <div class="detail-table">
                    <!-- row options: 
                    dBold: bold opisu (boolean),
                    vBold: bold wartości (boolean), 
                    short: tnie string i dodaje czytaj więcej (boolean),
                    cash: dodaje 'zł' 
                    -->
                    <row :desc="'Waluta kredytu'" :value="item.waluta"/>
                    <row :desc="'Rodzaj rat'" :value="parse_installment_type" />
                    <row :desc="'Produkt dostępny online'" :value="item.produkt_dostepny_online" :convertToIcons="true"/>
                    <row :desc="'Produkt dostępny w oddziale'" :value="item.produkt_dostepny_w_oddziale" :convertToIcons="true"/>
                    <row :desc="'Produkt dostępny przez telefon'" :value="item.produkt_dostepny_przez_telefon" :convertToIcons="true"/>
                    <row :desc="'Kwota kredytu od'" :value="parse_amount_from" :unit="'zł'"/>
                    <row :desc="'Kwota kredytu do'" :value="parse_amount_to" :unit="'zł'"/>
                    <row :desc="'Okres kredytowania od'" :value="parse_period_from" :unit="'miesięcy'"/>
                    <row :desc="'Okres kredytowania do'" :value="parse_period_to" :unit="'miesięcy'"/>
                    <row :desc="'Oprocentowanie'" :value="parse_percent" :unit="'%'"/>
                    <row :desc="'Prowizja'" :value="parse_commission" :unit="'%'"/>
                    <row :desc="'Ubezpieczenie obowiązkowe'" :value="item.ubezpieczenie_obowiazkowe" :convertToIcons="true"/>
                    <row :desc="'Ubezpieczenie'" :value="filterBy({filter: 'beautifyNumbers', value: item.ubezpieczenie})" :unit="'%'"/>
                    <row :desc="'Inne koszty (opłata przygotowawcza, opłata administracyjna itd..)'" :value="parse_other_costs"/>
                    <row :desc="'Sposób podpisania umowy - oddział'" :value="item.sposob_podpisywania_umowy_oddzial" :convertToIcons="true"/>
                    <row :desc="'Sposób podpisania umowy - kurier'" :value="item.sposob_podpisywania_umowy_kurier" :convertToIcons="true"/>
                    <row :desc="'Sposób podpisania umowy - przelew'" :value="item.sposob_podpisywania_umowy_przelew" :convertToIcons="true"/>
                    <row :desc="'Minimalny wiek kredytobiorcy'" :value="parse_age_from"/>
                    <row :desc="'Maksymalny wiek kredytobiorcy'" :value="parse_age_to"/>
                    <row :desc="'Czas oczekiwania na decyzję kredytową w dniach roboczych'" :value="item.czas_oczekiwania_na_decyzje_kredytowa"/>
                    <row :desc="'Wymagane dokumenty'" :value="item.required_documents"/>
                    <row :desc="'Opłata za rozpatrzenie wniosku w zł'" :value="item.oplata_za_rozpatrzenie_wniosku" :unit="'zł'"/>
                    <row :desc="'Opłata za przelew kwoty kredytu na rachunek w innym banku'" :value="item.oplata_za_przelew_kwoty_kredytu_na_rachunek_w_innym_banku" :unit="'zł'"/>
                    <row :desc="'Opłata za wczesniejszą spłatę'" :value="item.oplata_za_wczesniejsza_splate" :unit="'zł'"/>
                    <row :desc="'Wymagany rachunek osobisty'" :value="item.wymagany_rachunek_osobisty" :convertToIcons="true"/>
                    <row :desc="'Wymagana zgoda współmałżonka'" :value="parse_spouse_agree"/>
                    <row :desc="'Wady oferty'" :value="item.wady_oferty"/>
                    <row :desc="'Zalety oferty'" :value="item.zalety_oferty"/>
                    <row v-if="item.dodatkowe_informacje !== ''" :desc="'Dodatkowe informacje'" :value="item.dodatkowe_informacje"/>
                    <row :desc="'Przykład reprezentatywny'" :value="item.przyklad_reprezentatywny"/>

                </div>
            </div>
            <div class="details-short">
                
                <short  :title="'Informacje'"
                        :list="[{
                                    desc: 'Nazwa banku:',
                                    value: item.nazwa_banku.nazwa_banku
                                },{
                                    desc: 'Nazwa produktu:',
                                    value: item.nazwa_produktu
                                }]"
                />
                <short  :title="'Informacje o kredycie'"
                        :list="[{
                                    desc: 'Kwota kredytu od:',
                                    value: `${parse_amount_from} zł`
                                },{
                                    desc: 'Kwota kredytu do:',
                                    value: `${parse_amount_to} zł`
                                },{
                                    desc: 'Okres kredytowania od:',
                                    value: `${parse_period_from} miesięcy`
                                },{
                                    desc: 'Okres kredytowania do:',
                                    value: `${parse_period_to} miesięcy`
                                },{
                                    desc: 'Oprocentowanie:',
                                    value: `${parse_percent} %`
                                }]"
                />

            <div class="cta-wrapper">
                <a :href="parse_link" target="_blank">
                    <button type="button" class="button dark-blue large setwidth">Wybierz</button>
                </a>
                <!-- <div class="mail"><i class="fa fa-envelope" aria-hidden="true"></i> Wyślij ofertę na e-mail</div> -->
            </div>
            </div>
        </div>
        <modalWrapper v-if="orderContact" @close="orderContact=false" 
                        :maxWidth="'600px'" 
                        :secBtn="true" 
                        :secBtnText="'Zamów rozmowę'" 
                        :secBtnFN="patch" 
                        :secBtnHideIf="item.order_contact" 
                        :spinner='loading'
                        :inactive='loading'
                        >
            <template slot="title">Zamów rozmowę do produktu XXXXXXXXXXXXXXX</template>
            <template slot="content">
                <modalSummary :item="item"></modalSummary>
            </template>
        </modalWrapper>
    </div>
</template>

<script>
import row from './detailsRow'
import short from './detailsShortInfo'
import modalWrapper from '../modalWrapper';
import modalSummary from '../order_contact/summary';
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';

export default {
    components: {
        row, short, modalWrapper, modalSummary
    },
    props: ['item','period'],
    data() {
        return {
            orderContact: false,
            loading: false
        }
    },
    computed: {
        parse_installment_type() {
            return (this.item.rodzaj_rat == 0 || this.item.rodzaj_rat == 1) ? 'stałe' : 'malejące'
        },
        parse_amount_from() {
            let value = (this.item.hasOwnProperty('kwota_kredytu_od_dane_szczegoly')) ? this.item.kwota_kredytu_od_dane_szczegoly : this.item.kwota_kredytu_od
            return this.filterBy({filter: 'beautifyNumbers', value})
        },
        parse_amount_to() {
            let value = (this.item.hasOwnProperty('kwota_kredytu_do_dane_szczegoly')) ? this.item.kwota_kredytu_do_dane_szczegoly : this.item.kwota_kredytu_do
            return this.filterBy({filter: 'beautifyNumbers', value})
        },
        parse_period_from() {
            let value = (this.item.hasOwnProperty('okres_kredytowania_od_dane_szczegoly')) ? this.item.okres_kredytowania_od_dane_szczegoly : this.item.okres_kredytowania_od
            return this.filterBy({filter: 'beautifyNumbers', value})
        },
        parse_period_to() {
            let value = (this.item.hasOwnProperty('okres_kredytowania_do_dane_szczegoly')) ? this.item.okres_kredytowania_do_dane_szczegoly : this.item.okres_kredytowania_do
            return this.filterBy({filter: 'beautifyNumbers', value})
        },
        parse_percent() {
            return this.filterBy({filter: 'beautifyNumbers', value: Number(this.item.oprocentowanie)*100})
        },
        parse_commission() {
            return this.filterBy({filter: 'beautifyNumbers', value: Number(this.item.prowizja)*100})
        },
        parse_other_costs() {
            return (this.item.inne_koszty == 0) ? 'brak' : `${this.item.inne_koszty} zł` 
        },
        parse_age_from() {
            return (this.item.minimalny_wiek_kredytobiorcy == 0) ? 'brak' : `${this.item.minimalny_wiek_kredytobiorcy}` 
        },
        parse_age_to() {
            return (this.item.maksymalny_wiek_kredytobiorcy == 0) ? 'brak' : `${this.item.maksymalny_wiek_kredytobiorcy} zł` 
        },
        parse_spouse_agree() {
            return (this.item.wymagana_zgoda_wspolmalzonka == 0) ? 'nie' : this.item.wymagana_zgoda_wspolmalzonka
        },
        parse_link() {
            return `https://finanse.rankomat.pl/kredyty/kalkulator-gotowkowy/wniosek/?scoretype=cash&amount=25000&period=${Number(this.period)*12}&id=${this.item.id}&ctype=cash`;
        }
    },
    methods: {
        filterBy({filter, value}) {
            return this.$options.filters[filter](value)
        }
    },
    filters: {
        beautifyNumbers(value) {
            let twoDecimalPlaces = Number(value).toFixed(2)
            let dotToComa = String(twoDecimalPlaces).replace('.',',');
            if (dotToComa.indexOf(',00') > -1) dotToComa = dotToComa.replace(',00','')
            let polished = dotToComa.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return polished
        }
    }
}
</script>

<style lang='scss' scoped>
$small: 450px;
$blue: #20a3dd;

@mixin button-style($bg-color, $font-color, $shadow-color, $font-size, $padding, $max-width) {
    &:focus {
        outline: none;
    }
    display: inline-block;
    max-width: $max-width;
    width: 100%;
    margin: 0 5px;
    font-size: $font-size;
    padding: $padding;
    background: $bg-color;
    color: $font-color;
    box-shadow: 0 2px 0 0 $shadow-color;
    border: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Montserrat", Helvetica Neue, Arial, sans-serif;
    line-height: 1;
    cursor: pointer;
    border-radius: 30px;
    transition: all .1s linear;
    &:hover {
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, .05);
    }
}

.mod-body {
    .header {
    display: flex;
    align-items: center;
    padding: 30px;
    background-color: #FFFFFF;
    flex-wrap: wrap;
        .logo-wrapper {
            order: 1;
            flex: 0 0 33.33%;
            @media screen and (max-width: $small) {
                flex: 0 0 50%;
            }
            img {
                width: 160px;
                height: auto;
            }
        }
        .price-wrapper {
            order: 2;
            flex: 0 0 33.33%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: $small) {
                flex: 0 0 50%;
            }
            .special {
                padding: 2px 8px;
                font-size: 9px;
                font-weight: 600;
                background-color: #20a3dd;
                color: #fff;
                border-radius: 20px;
                max-width: 130px;
            }
            .price {
                color: #012174;
                font-size: 28px;
                font-weight: 700;
                text-align: center;
                .smaller {
                    font-size: 16px;
                }
            }
            .description {
                color: #555555;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }
    .body {
        padding: 30px;
        background-color: #f3f3f3;
        display: flex;
        justify-content: space-between;
        .details-full {
            flex: 0 0 65%;
            background-color: white;

            @media screen and (max-width: $small) {
                flex: 0 0 100%;
            }

            .detail-table {
                border-top: 1px dashed #e9e9e9;
                border-right: 1px dashed #e9e9e9;
                border-left: 1px dashed #e9e9e9;

            }
            .header {
                background-color: #f3f3f3;
                text-align: left;
                padding: 10px 5px;
                font-weight: 600;
                color: #012174;
            }
        }
        .details-short {
            flex: 0 0 32%;

            @media screen and (max-width: $small) {
                display: none;
            }
        }
    }

    .cta-wrapper {
        order: 3;
        flex: 0 0 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: $small) {
            flex: 0 0 100%;
            margin-top: 30px;
        }
        .button {
            @include button-style(#012174,#fff,#3d60c7, 11px, 10px, 250px)
        }

        .light-blue {
            @include button-style($blue,#fff, #2074dd , 11px, 10px, 250px);
        }

        .dark-blue {
            @include button-style(#6bcd44,#fff,#52b131, 11px, 15px 20px, 250px);
            margin-bottom: 7px;
        }
        .setwidth {
            width: 150px;
        }
        .mail {
            margin: 15px 15px 0;
            color: #20a3dd;
            font-weight: 400;
        }
        .green-box {
            color: #88d261;
            .text {
                color: #333;
                font-size: 12px;
                line-height: 12px;
                margin-top: 3px;
            }
        }
    }
}
</style>
