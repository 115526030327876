<template>
    <div class="creditworthiness-calculation-wrapper">
        <div class="header">Twoja zdolność kredytowa:</div>
        <div class="value">{{cw_value | addSeparator}} zł</div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    computed: {
        ...mapState(['creditworthiness']),
        cw_value() {
            let value = Math.floor(this.creditworthiness.creditworthiness)
            return value
        },
    },
    filters: {
        addSeparator(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    },
}
</script>

<style lang="scss" scoped>
.creditworthiness-calculation-wrapper {
    width: 100%;
    .header {
        margin-top: 30px;
        font-size: 34px;
        font-weight: 600;
        color: #012174;
        margin-bottom: 0;
        text-align: center;
    }
    .value {
        font-size: 40px;
        font-weight: 600;
        color: #20a3dd;
        margin-bottom: 0;
        text-align: center;
        margin-bottom: 5px;
    }
}
</style>