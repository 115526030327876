<template>
    <div :id="name" class="agreements-wrapper" :class="{'fullWidth': hideTitle}" :style="{padding}">
        <div class="agreements-title" v-if="!hideTitle">
            {{title}}
        </div>
        <div class="agreement-box">
            <agreement v-for="(agreement, index) in agreements" :key="index" :agreement="agreement" :singleErrors="singleErrors"/>
            <div class="error-box" v-if="!singleErrors && agreementsErrorGetter">
                <slot name="singleErrorMsg">Aby przejść dalej zaakceptuj zgody</slot>
            </div>
        </div>
    </div>
</template>

<script>
import agreement                            from './_kAgreement.vue'

export default {
    computed: {
        agreementsErrorGetter() {
            return this.agreements.some(item => (item.required && item.error))
        },
        isAllAccepted() {
            return this.agreements.filter(item => item.name !== 'acceptAll').every(item => item.value === true)
        }
    },
    props: {
        singleErrors: {
            type: Boolean,
            default: true
        },
        agreements: {
            type: Array,
        },
        title: {
            type: String,
            default: 'Zgody wymagane do realizacji usługi'
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'agreements'
        },
        padding: {
            type: String,
            default: '7px 10px'
        }

    },
    components: {
        agreement
    },
    watch: {
        isAllAccepted(value) {
            let acceptAll = this.agreements.find(item => item.name === 'acceptAll');
            acceptAll.value = value
        }
    }
}
</script>

<style lang="scss" scoped>
$small: 640px;

.agreements-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.fullWidth {
        justify-content: center;
        .agreement-box {
            flex: 0 0 85%;
        }
    }
    .agreements-title {
        background: #fff !important;
        font-weight: 600;
        font-size: 13px;
        padding: 5px 15px;
        position: relative;
        text-align: right;
        flex: 0 0 40%;
        @media screen and (max-width: $small) {
            flex: 0 0 90%;
            text-align: left;
        }
    }
    .agreement-box {
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        flex: 1;
        @media screen and (max-width: $small){
            flex: 0 0 90%;
        }
        .error-box {
            color: #4d3f24;
            background-color: #f8ecc0;
            // border-color: #ffeeaa;
            margin-bottom: 14px;
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 8px 10px;
            font-size: 13px;
            display: block;
            float: left;
            width: 100%
        }
    }
}
</style>
