<template>
    <div class="kSelect-wrapper" :id="'kselect-' + name" :class="{'kSelect-wrapper--small': smallSelect}">
        <input type="text" class="formInput" @click="showOptionBox" :value="displayedValue" @keydown="writable" @input="input" :class="{'filled': isFilled}" :disabled="!enable" :placeholder="placeholder">
        <div class="kOption-wrapper" v-if="showOptions">
            <div v-if="!displayFirstTitle" class="kOption kOption--title">{{optionTitle}}</div>
            <div class="kOption kOption--message" v-if="listPattern.length === 0">Brak wyników</div>
            <div v-for="(item, index) in listPattern" :key="`${index}_option`" class="kOption" :class="{'kOption--selected': item.value == value}" @click="selectItem(item)">{{item.label}}</div>
            <template v-if="secondItems.length > 0">
                <div class="kOption kOption--title kOption--marginTop">{{secondOptionTitle}}</div>
            <div class="kOption kOption--message" v-if="secondListPattern.length === 0">Brak wyników</div>
                <div v-for="(item, index) in secondListPattern" :key="`${index}_second_option`" class="kOption" :class="{'kOption--selected': item.value == value}" @click="selectItem(item)">{{item.label}}</div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            default: "Wybierz",
            type: String
        },
        displayFirstTitle: {
            default: false,
            type: Boolean
        },
        optionTitle: {
            default: "Wybierz jedną z poniższych opcji",
            type: String
        },
        secondOptionTitle: {
            default: "Wybierz jedną z poniższych opcji",
            type: String
        },
        items: {
            default: () => {
                return [
                    // {label: "przykładowa opcja: 1", value: "1"},
                    // {label: "przykładowa opcja: 2", value: "2"},
                ]},
            type: Array
        },
        secondItems: {
            default: () => {
                return []},
            type: Array
        },
        value: [String, Number],
        name: {
            required: true,
            type: String
        },
        valuesameaslabel: {
            default: true,
            type: Boolean
        },
        enable: {
            default: true,
            type: [String, Number, Boolean]
        },
        sort: {
            default: 'asc',
            type: String
        },
        disableSorting: {
            default: false,
            type: Boolean
        },
        search: {
            default: true,
            type: Boolean
        },
        customItems: {
            default: false,
            type: Boolean
        },
        displayLabel: {
            default: true,
            type: Boolean
        },
        smallSelect: {
            default: false,
            type: Boolean
        }
    },
    created() {
        // this.$emit('input', '');
    },
    data() {
        return {
            showOptions: false,
            displayedValue: ''
        }
    },
    methods: {
        selectItem(item) {
            if (this.displayLabel) this.displayedValue = item.label
            else this.displayedValue = item.value;
            this.$emit('input',item.value);
            this.showOptions = false;
        },
        outsideClose(e) {
            var el = document.querySelector(`#kselect-${this.name}`);
            var target = e.target;
            if ( el !== target && !el.contains(target)) {
                this.showOptions = false;
                if (this.search) this.displayedValue = this.value;
            }
        },
        showOptionBox() {
            if (this.enable) {
                if (this.search) this.displayedValue = '';
                this.showOptions = true;
            }
        },
        input(e) {
            if (this.search) {
                let value = e.target.value;
                this.displayedValue = value;
            }
        },
        writable(e) {
            if (!this.search) {
                e.preventDefault();
            } 
        },
    },
    computed: {
        isFilled() {
            return this.listPattern.find(item => item.value == this.value)
        },
        listPattern() {
                if (!this.customItems) {
                    var arr =  this.items.map((item, index) => {
                        let label = item;
                        return {label, value: (this.valuesameaslabel) ? label : String(index)}
                    })
                } else var arr = this.items
                

                if (this.search) arr = arr.filter(item => String(item.label).toLowerCase().indexOf(String(this.displayedValue).toLowerCase()) > -1);

                function sorting(a,b) {
                    a = String(a.label).toLowerCase();
                    b = String(b.label).toLowerCase();

                    if (this.sort === 'asc') {
                        if (a < b) return -1
                        if (a > b) return 1
                    }
                    if (this.sort === 'desc') {
                        if (a < b) return 1
                        if (a > b) return -1
                    }
                    return 0
                }

                if (!this.disableSorting) return arr.sort(sorting.bind(this))
                return arr
        },
        secondListPattern() {
                let arr =  this.secondItems.map((item, index) => {
                    let label = item;
                    return {label, value: (this.valuesameaslabel) ? label : String(index)}
                })

                arr = arr.filter(item => String(item.label).toLowerCase().indexOf(String(this.displayedValue).toLowerCase()) > -1);

                function sorting(a,b) {
                    a = String(a.label).toLowerCase();
                    b = String(b.label).toLowerCase();

                    if (this.sort === 'asc') {
                        if (a < b) return -1
                        if (a > b) return 1
                    }
                    if (this.sort === 'desc') {
                        if (a < b) return 1
                        if (a > b) return -1
                    }
                    return 0
                }

                if (!this.disableSorting) return arr.sort(sorting.bind(this))
                return arr
        }

    },
    watch: {
        showOptions(value) {
            if (value) document.addEventListener('click', this.outsideClose);
            else document.removeEventListener('click', this.outsideClose)
        },
        value: {
            handler(value) {
                if (this.displayLabel) this.displayedValue = this.listPattern.find((item) => item.value == value).label 
                else this.displayedValue = value
            },
            // immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.kSelect-wrapper {
    width: 100%;
    position: relative;
    font-size: 13px;
    color: #0a0a0a;
    
    input {
        order: 0;
        display: flex;
        flex: 0 1 100%;
        height: 44px;
        box-sizing: border-box;
        font-weight: 600;
        text-align: left;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #ddd;
        font-size: 13px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        margin-bottom: 0px;
        &::placeholder {
            font-weight: 400;
            color: rgb(156, 156, 156);
        }
        &:focus {
            outline-width: 0;
        }
    }
    &::after {
        content: '';
        position: absolute;
        top: 20px;
        right: 10px;
        border: 5px solid transparent;
        border-top-color: grey;
        transform: scaleX(0.7);
        z-index: 10;
    }
    .formInput {
        width: 100%;
        padding-right: 30px;
        position: relative;
        cursor: pointer;
        padding-left: 10px;
        &:focus {
            box-shadow: 0 0 0 4px rgba(32, 164, 222, 0.25);
            border-color: rgba(32, 163, 221, 0.6);
            z-index: 1;
        }
    }
    .kOption-wrapper {
        position: absolute;
        top: 48px;
        left: 0px;
        max-height: 250px;
        overflow-y: auto;
        border-radius: 4px;
        width: inherit;
        padding: 5px 0px;
        background-color: #fff;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 11px;
        .kOption {
            // width: inherit;
            padding: 5px 20px;
            font-weight: 600;
            &:hover {
                background-color: #f5f5f5;
                cursor: pointer;
            }

            &--title {
                color: #20a3dd;
                &:hover {
                    background-color: #fff;
                    cursor: default;
                }
            }

            &--message {
                color: #ddd;
                font-weight: 400;
                &:hover {
                    background-color: white;
                    cursor: default;
                }
            }

            &--marginTop {
                margin-top:10px;
            }

            &--selected {
                color: white;
                background-color: #20a3dd;
                &:hover {
                    background-color: #20a3dd;
                    cursor: pointer;
                }
            }

        }
    }

    &--small {
        &::after {
            top: 12px;
        }
        input {
            height: 28px;

        }

        .kOption-wrapper {
            top: 28px;
        }
    }
}
</style>

